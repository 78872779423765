import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import styles from './Loading.module.css';

const Loading = ({ text, className }) => {
  return (
    <div className={`${styles.loadingInfo} ${className ? className : ''}`}>
      <div className={styles.loadingIcon}>
        <Spinner />
      </div>
      {text && <p className={styles.loadingText}>{text}</p>}
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

Loading.defaultProps = {
  text: 'Loading...',
};

export default Loading;
