import PropTypes from 'prop-types';
import React from 'react';

import ColorPicker from './ColorPicker';

export default class ColorPickerFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: this.props.color,
      attribute: this.props.attribute,
    };

    this.dispatchColor = this.dispatchColor.bind(this);
  }

  dispatchColor = c => {
    this.setState({ color: c });
  };

  render() {
    return (
      <div className="input-wrapper float-left">
        <ColorPicker {...this.props} dispatchColor={this.dispatchColor} />
        <input
          className="string optional hidden"
          type="text"
          value={this.state.color}
          name={`brand[${this.state.attribute}]`}
          id={`brand_${this.state.attribute}`}
          readOnly
        />
      </div>
    );
  }
}

ColorPickerFormWrapper.propTypes = {
  color: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
};
