import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './SearchBox.module.scss';

export const SearchBox = ({ map, mapsapi, placeholder }) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  useEffect(() => {
    if (!searchBox.current && input.current) {
      searchBox.current = new mapsapi.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', () => {
        const place = searchBox.current.getPlaces();
        if (place && place[0] && place[0].geometry) {
          map.setCenter(place[0].geometry.location);
          map.setZoom(12);
        }
      });
    }

    return () => {
      searchBox.current = null;
      mapsapi.event.clearInstanceListeners(searchBox);
    };
  }, [map, mapsapi, input.current]);

  const handleFocus = e => {
    e.target.value = '';
  };

  return (
    <input
      ref={input}
      placeholder={placeholder}
      type="text"
      className={styles.input}
      onFocus={handleFocus}
    />
  );
};

SearchBox.propTypes = {
  map: PropTypes.shape({
    setCenter: PropTypes.func,
    setZoom: PropTypes.func,
  }).isRequired,
  mapsapi: PropTypes.shape({
    places: PropTypes.shape({
      SearchBox: PropTypes.func,
    }),
    event: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
};
