import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs as TabsWrapper, TabList, TabPanel } from 'react-tabs';
import { Empty } from 'atoms';

import styles from './Tabs.module.css';

const Tabs = ({ tabsData, classes }) => {
  const {
    mainClassName,
    tabListClassName,
    tabClassName,
    selectedTabClassName,
    tabPanelClassName,
    selectedTabPanelClassName,
  } = classes;
  const tabsPanelRef = useRef(null);

  useEffect(() => {
    if (tabsPanelRef.current) {
      const minHeight = `${tabsPanelRef.current.clientHeight}px`;
      tabsPanelRef.current.style.minHeight = minHeight;
    }
  }, [tabsPanelRef, tabsData]);

  const handleUrl = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    location.href = url;
  };

  const tabsArray = tabsData.map(({ header, url }, index) => {
    return (
      <Tab
        key={`tab-${index}`}
        className={tabClassName}
        {...(url && {
          onClick: event => handleUrl(event, url),
        })}
      >
        {header}
      </Tab>
    );
  });

  const tabsPanelsArray = tabsData.map(({ content }, index) => {
    return (
      <TabPanel key={`tabPanel-${index}`} className={tabPanelClassName}>
        {content ? content : <Empty />}
      </TabPanel>
    );
  });

  return (
    <TabsWrapper
      className={`${styles.tabsWrapper} ${mainClassName}`}
      {...{ selectedTabClassName }}
      {...{ selectedTabPanelClassName }}
    >
      <TabList className={tabListClassName}>{tabsArray}</TabList>
      <div ref={tabsPanelRef} className="tabsPanelWrapper">
        {tabsPanelsArray}
      </div>
    </TabsWrapper>
  );
};

Tabs.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      content: PropTypes.element,
    }),
  ).isRequired,
  classes: PropTypes.shape({
    mainClassName: PropTypes.string,
    tabListClassName: PropTypes.string,
    tabClassName: PropTypes.string,
    selectedTabClassName: PropTypes.string,
    tabPanelClassName: PropTypes.string,
    selectedTabPanelClassName: PropTypes.string,
  }),
};

Tabs.defaultProps = {
  classes: {
    tabListClassName: 'nav nav-tabs',
    tabClassName: 'nav-item nav-link ',
    selectedTabClassName: 'active',
  },
};

export default Tabs;
