import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../../atoms';

import styles from '../trackingDashboard.module.scss';

const RailsNavigation = ({
  ui,
  mapLayer,
  showDevicesList,
  showMap,
  filterDevices = () => {},
}) => {
  return (
    <nav
      className={`fixed-top navbar navbar-expand-lg full-mobile ${ui.navbarColorClass} ${styles.nav}`}
      style={{ backgroundColor: ui.primaryColor }}
    >
      <button
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
        className="navbar-toggler mr-2"
        data-action="click->navigation#toggleSidebarLeft"
        data-toggle="collapse"
        id="sidebar-toggler-left"
        type="button"
      >
        <span className="navbar-toggler-icon" />
      </button>
      {/* Temporarily attach brand logo #177344197 */}
      <a href="/">
        <img className={styles.brandLogo} src={ui.dashboardLogo} />
      </a>
      {/* Temporarily hide search bar #176202108
      <TextField
        placeholder="Search ID or Name"
        iconName="fa-search"
        onInput={filterDevices}
        maxWidth="210px"
      /> */}
      <div className={`${styles.toggler} ml-2`}>
        <button
          type="button"
          className={styles.togglerButton}
          onClick={showMap}
          style={{
            backgroundColor: `${(mapLayer && ui.primaryColor) ||
              'transparent'} `,
            filter: `${(mapLayer &&
              ui.navbarColorClass === 'navbar-light' &&
              'brightness(75%)') ||
              (mapLayer &&
                ui.navbarColorClass === 'navbar-dark' &&
                'brightness(150%)') ||
              'none'} `,
          }}
        >
          <i className="fal fa-globe" />
        </button>
        <button
          type="button"
          className={styles.togglerButton}
          onClick={showDevicesList}
          style={{
            backgroundColor: `${(!mapLayer && ui.primaryColor) ||
              'transparent'} `,
            filter: `${(!mapLayer &&
              ui.navbarColorClass === 'navbar-light' &&
              'brightness(75%)') ||
              (!mapLayer &&
                ui.navbarColorClass === 'navbar-dark' &&
                'brightness(150%)') ||
              'none'} `,
          }}
        >
          <i className="fal fa-th-list" />
        </button>
      </div>
    </nav>
  );
};

RailsNavigation.propTypes = {
  ui: PropTypes.shape({
    navbarColorClass: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    dashboardLogo: PropTypes.string,
  }),
  mapLayer: PropTypes.bool.isRequired,
  showDevicesList: PropTypes.func.isRequired,
  showMap: PropTypes.func.isRequired,
  filterDevices: PropTypes.func,
};

export default RailsNavigation;
