import React, { useEffect, useState } from 'react';

const useCalculateHeight = (ref, modifier) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(
      `calc(93vh - ${
        ref.current ? ref.current.getBoundingClientRect().top : '0'
      }px + ${modifier ? modifier : '0'}px)`,
    );
  }, []);

  return height;
};

export default useCalculateHeight;
