import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'atoms';

import styles from './geozonesTable.module.scss';

const Color = ({ color }) => (
  <div
    className={styles.zoneColourIcon}
    style={{ backgroundColor: color }}
  ></div>
);
Color.propTypes = {
  color: PropTypes.string,
};

const EditButton = ({ zoneId, ...rest }) => {
  return (
    <div role="button" className={styles.editZoneButton} {...rest}>
      <i className="fas fa-pencil-alt"></i>
    </div>
  );
};
EditButton.propTypes = {
  zoneId: PropTypes.number,
};

const ColorCell = props => <Color color={props.row.values.color} />;
ColorCell.propTypes = {
  row: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
    }),
  }),
};

const GeozonesTable = ({ data, onEdit }) => {
  const EditButtonCell = props => (
    <EditButton
      zoneId={props.row.values.id}
      onClick={() => onEdit(props.row.original.id)}
    />
  );
  EditButtonCell.propTypes = ColorCell.propTypes;

  const columns = useMemo(
    () => [
      {
        Header: 'Colour:',
        accessor: 'color',
        Cell: ColorCell,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: () => null,
        id: 'edit',
        Cell: EditButtonCell,
      },
    ],
    [],
  );

  return (
    <Table
      {...{
        columns,
        data,
        className: styles.geozonesTable,
        emptyComponent: <Empty> No Geozones added yet </Empty>,
      }}
    />
  );
};

GeozonesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
};

export default GeozonesTable;
