// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

import { Application as Stimulus } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import setupCSRFToken from '../setupCSRFToken';
import registerGlobalReactComponents from '../utils/registerGlobalReactComponents';

const stim = Stimulus.start();
const context = require.context('../controllers', true, /\.js$/);

stim.load(definitionsFromContext(context));
window.stimulus = stim;

setupCSRFToken();
registerGlobalReactComponents();
