import { useState, useEffect } from 'react';
import moment from 'moment';
import useSWR, { mutate } from 'swr';
import periodOptions from './periodOptions';

const useDeviceView = initialData => {
  const {
    device: { id, geozones, period },
  } = initialData;

  const [selectedPeriod, setSelectedPeriod] = useState([period[0], period[1]]);
  const [deviceState, setDeviceState] = useState(initialData.device);
  const [geolocationsAreHidden, setGeolocationsAreHidden] = useState(false);

  const endpointUrl =
    selectedPeriod && selectedPeriod.length > 1
      ? `${process.env.API_URL}/devices/${id}/?dataFrom=${
          encodeURIComponent(selectedPeriod[0])
        }&dataTo=${encodeURIComponent(selectedPeriod[1])}&forceLocationSource=${
          geolocationsAreHidden ? 'gps' : null
        }`
      : `${process.env.API_URL}/devices/${id}&forceLocationSource=${
          geolocationsAreHidden ? 'gps' : null
        }`;

  const { data: deviceFromApi, isValidating } = useSWR(endpointUrl, undefined, {
    initialData: deviceState,
  });
  const [markersData, setMarkersData] = useState(getMarkersData());

  useEffect(() => {
    setMarkersData(getMarkersData());
    setDeviceState(deviceFromApi);
  }, [deviceFromApi]);

  function getMarkersData() {
    const locationEvents = getFormattedLocationEvents(deviceFromApi.events);
    return locationEvents;

    function getFormattedLocationEvents(events) {
      return (
        events
          .filter(
            event =>
              event.type === 'location' &&
              event.locationAccurate &&
              ((event.data.source === 'device' &&
                event.properties.locationChanged) ||
                event.data.source === 'google_api'),
          )
          // events are sorted descending; in order to visualize device movement properly,
          // we need to reverse them
          .reverse()
          .map((event, index) => ({
            ...event.data,
            id: index + 1,
            timestamp: moment(event.timestamp).utc(),
            active: true,
          }))
      );
    }
  }

  function clearEventsList() {
    mutate(endpointUrl, { ...deviceFromApi, events: [] });
  }

  return {
    markersData,
    zonesData: geozones,
    selectedPeriod,
    setSelectedPeriod,
    deviceFromApi,
    clearEventsList,
    geolocationsAreHidden,
    setGeolocationsAreHidden,
    isValidating,
  };
};

export default useDeviceView;
