import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import { Empty } from 'atoms';

import styles from './TableCore.module.scss';

const TableCore = ({
  className,
  paginationClassName,
  rowComponent: RowComponent,
  emptyComponent: EmptyComponent,
  rowProps,
  showMap,
  tableProps: {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    flatColumns,
    state,
    ...paginationProps
  },
}) => {
  const isData = page.length > 0;
  const columnsNumber = flatColumns.length;

  return (
    <>
      <table {...{ ...getTableProps(), className }}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={`headerGroup-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={`headerGroup-column-${i}`}
                  {...(typeof column.getSortByToggleProps === 'function'
                    ? column.getHeaderProps(column.getSortByToggleProps)
                    : column.getHeaderProps())}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isData ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <RowComponent
                  key={`row-${i}`}
                  {...{ row, columnsNumber, showMap, ...rowProps }}
                />
              );
            })
          ) : (
            <tr>
              <td className="no-data-cell" colSpan={columnsNumber}>
                {EmptyComponent}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isData && (
        <Pagination
          {...{
            ...paginationProps,
            page,
            state,
            paginationClassName,
          }}
        />
      )}
    </>
  );
};

TableCore.propTypes = {
  className: PropTypes.string.isRequired,
  paginationClassName: PropTypes.string,
  rowComponent: PropTypes.func.isRequired,
  rowProps: PropTypes.object,
  tableProps: PropTypes.shape({
    getTableProps: PropTypes.func.isRequired,
    headerGroups: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    page: PropTypes.array.isRequired,
    prepareRow: PropTypes.func.isRequired,
    flatColumns: PropTypes.array.isRequired,
    state: PropTypes.object,
  }),
  emptyComponent: PropTypes.element,
  showMap: PropTypes.func,
};

TableCore.defaultProps = {
  className: 'table',
  paginationClassName: '',
  emptyComponent: <Empty />,
};

export default TableCore;
