import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoCard.module.scss';

const InfoCard = ({ title, subtitle, smaller }) => (
  <div className={`card ${styles.infoCard} ${smaller && styles.smaller}`}>
    <div className="card-body text-center">
      <h2 className="card-title">{title}</h2>
      <p className="card-text">{subtitle}</p>
    </div>
  </div>
);

InfoCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  smaller: PropTypes.bool,
};

export default InfoCard;
