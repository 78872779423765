import React from 'react';
import PropTypes from 'prop-types';

import styles from './Thumbnail.module.css';

const Thumbnail = ({ image, title, className, style }) => {
  return (
    <div
      className={`${styles.thumbnail} ${className ? className : ''}`}
      {...{ style }}
    >
      {image ? (
        <img className={styles.image} src={image} alt={title} />
      ) : (
        <div className={styles.placeholder}></div>
      )}
    </div>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

Thumbnail.defaultProps = {};

export default Thumbnail;
