import PropTypes, { string } from 'prop-types';
import React from 'react';
import { Table } from 'atoms';
import moment from 'moment';
import { eventsType } from '../types';
import underscoresToSpaces from '../../../utils/underscoresToSpaces/underscoresToSpaces';

import styles from './eventsTable.module.scss';

export const EventsTable = ({
  selectedTypes,
  events,
  getBadgeClass = () => '',
}) => {
  const TypeCell = ({ cell: { value } }) => (
    <span className={`badge ${getBadgeClass(value)}`}>
      {underscoresToSpaces(value)}
    </span>
  );
  TypeCell.propTypes = {
    cell: PropTypes.shape({
      value: string,
    }),
  };

  const columns = [
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      Cell: ({ cell: { value } }) =>
        moment.utc(value).format('YYYY-MM-DD HH:mm:ss UTC'),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: TypeCell,
    },
    {
      Header: 'Body',
      accessor: row => ({
        id: row.id,
        ...row.data,
        actionDetails: row.actionDetails,
        outcomeDetails: row.outcomeDetails,
        properties: row.properties,
      }),
      Cell: ({ cell: { value } }) =>
        value && <pre>{JSON.stringify(value, null, 2)}</pre>,
    },
  ];

  return (
    <Table
      columns={columns}
      data={
        selectedTypes.length
          ? events.filter(event => selectedTypes.includes(event.type))
          : events
      }
      className={`${styles.eventsTable} table table-bordered rounded`}
    />
  );
};

EventsTable.propTypes = {
  events: eventsType,
  selectedTypes: PropTypes.array,
  getBadgeClass: PropTypes.func,
};
