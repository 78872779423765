import React, { useState, useEffect } from 'react';
import axios from 'axios';
import flatpickr from 'flatpickr';

import { getApiDateString } from 'utils';
import useDebounce from '../../../../../hooks/useDebounce';

export const useGeneral = ({
  dateInputRef,
  id,
  onChange = () => {},
  flightModeDurationInSeconds,
}) => {
  const [error, setError] = useState('');
  const [flightModeDuration, setFlightModeDuration] = useState(
    flightModeDurationInSeconds,
  );

  const debouncedFlightModeDuration = useDebounce(flightModeDuration, 500);

  useEffect(() => {
    if (dateInputRef.current) {
      flatpickr(dateInputRef.current, {
        onChange: onUpdateInspectionDate,
        dateFormat: 'd/m/y',
        minDate: Date.now(),
      });
    }
  }, [dateInputRef]);

  const endpointUrl = `${process.env.API_URL}/devices/${id}`;
  const onUpdateFrequencyChange = async value => {
    const data = {
      updateFrequencyInSeconds: value,
    };

    setError('');
    await axios.put(endpointUrl, data).catch(error => setError(error.message));

    onChange();
  };

  const onFlightModeDurationChange = event => {
    setError('');
    setFlightModeDuration(event.target.value);
  };

  const onUpdateInspectionDate = async ([selectedDate]) => {
    const dateString = getApiDateString(selectedDate);
    const data = {
      inspectionDate: dateString,
    };

    setError('');
    await axios.put(endpointUrl, data).catch(error => setError(error.message));

    onChange();
  };

  useEffect(() => {
    let ignore = false;

    const changeDuration = async () => {
      await axios
        .put(endpointUrl, {
          flightModeDurationInSeconds: debouncedFlightModeDuration,
        })
        .catch(error => {
          if (!ignore) {
            setError(error.message);
          }
        });

      onChange();
    };
    changeDuration();

    return () => {
      ignore = true;
    };
  }, [debouncedFlightModeDuration]);

  return {
    onUpdateFrequencyChange,
    onFlightModeDurationChange,
    error,
    flightModeDuration,
  };
};

export default useGeneral;
