import { useLayoutEffect, useState } from 'react';

export default function useMediaQuery(mediaQuery) {
  const [matches, setMatches] = useState(() => {
    window.matchMedia(mediaQuery).matches;
  });

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const listener = e => setMatches(e.matches);
    mediaQueryList.addListener(listener);

    setMatches(mediaQueryList.matches);

    return () => mediaQueryList.removeListener(listener);
  }, [mediaQuery]);

  return matches;
}
