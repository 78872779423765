import React from 'react';
import { SWRConfig } from 'swr';
import { fetchApi } from 'utils';
import TrackingDashboard from './TrackingDashboard';

export const APILinkedTrackingDashboard = props => (
  <SWRConfig
    value={{
      fetcher: fetchApi,
    }}
  >
    <TrackingDashboard {...props} />
  </SWRConfig>
);

export default props => <APILinkedTrackingDashboard {...props} />;
