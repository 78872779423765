import React, { useState, useEffect } from 'react';
import { useDebounce, useControlledInput } from 'hooks';

function useTrackingDashboard(initialData) {
  const [tableData, setTableData] = useState(initialData);
  const [rowsData, setRowsData] = useState();
  const [mapData, setMapData] = useState(
    initialData.filter(point => point.lat && point.lng && point.status),
  );
  const [expandedDevice, setExpandedDevice] = useState();
  const [columnsNumber, setColumnsNumber] = useState(0);
  const [mapLayer, setMapLayer] = useState(true);
  const [deviceIsReady, setDeviceIsReady] = useState(false);

  const showMap = () => {
    setMapLayer(true);
  };

  const showDevicesList = () => {
    setMapLayer(false);
  };

  const deviceLoaded = () => {
    setDeviceIsReady(true);
  };

  const deviceNotLoaded = () => {
    setDeviceIsReady(false);
  };

  const {
    inputValue: searchFilter,
    changeHandler: filterDevices,
  } = useControlledInput('');

  const INPUT_FILTER_DELAY_IN_MS = 350;
  const debouncedSearchTerm = useDebounce(
    searchFilter,
    INPUT_FILTER_DELAY_IN_MS,
  );

  useEffect(() => {
    setTableData(
      searchFilter
        ? initialData.filter(
            device => isIdMatched(device) || isNameMatched(device),
          )
        : initialData,
    );
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const devicesToDisplay = !!rowsData
      ? rowsData.map(({ original, isExpanded }) =>
          isExpanded ? { ...original, active: true } : original,
        )
      : tableData;

    setMapData(devicesToDisplay);
  }, [rowsData]);

  useEffect(() => {
    const device = !!rowsData
      ? rowsData.find(row => row.isExpanded)
      : undefined;

    setExpandedDevice(device);
  }, [rowsData]);

  const getRowsData = data => {
    setRowsData(data);
  };

  const onPointerClick = pointerId => {
    const { toggleExpanded, isExpanded } = rowsData.find(row => {
      return row.original.id === pointerId;
    });
    toggleExpanded(!isExpanded);
  };

  return {
    tableData,
    mapData,
    searchFilter,
    filterDevices,
    getRowsData,
    onPointerClick,
    showMap,
    showDevicesList,
    mapLayer,
    expandedDevice,
    setColumnsNumber,
    columnsNumber,
    deviceIsReady,
    deviceLoaded,
    deviceNotLoaded,
  };

  function isNameMatched(device) {
    if (!device.name) {
      return false;
    }

    return device.name
      .toUpperCase()
      .includes(debouncedSearchTerm.toUpperCase());
  }

  function isIdMatched(device) {
    return `${device.id}` === debouncedSearchTerm;
  }
}

export default useTrackingDashboard;
