import PropTypes from 'prop-types';
import { DEVICE_STATUSES } from './../DeviceView/types';

const {
  shape,
  number,
  string,
  oneOf,
  arrayOf,
  instanceOf,
  oneOfType,
  bool,
  object,
} = PropTypes;

export const deviceType = shape({
  id: oneOfType([string, number]),
  name: string,
  lat: number,
  lng: number,
  status: oneOf(DEVICE_STATUSES),
  lastConnected: oneOfType([instanceOf(Date), string]),
  boxOpened: oneOfType([instanceOf(Date), string]),
  batteryLevel: number,
});

export const initialDataType = shape({
  devices: arrayOf(deviceType),
  contextualData: object,
  routes: shape({
    activateDevice: string,
  }),
});
