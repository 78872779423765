import React from 'react';
import {
  string,
  arrayOf,
  number,
  shape,
  oneOfType,
  instanceOf,
} from 'prop-types';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import colors from '../../../assets/stylesheets/_colors.scss';
import styles from '../../components/DeviceView/deviceView.module.scss';

export const MixedChart = ({
  periodName,
  stackedBarDataset1,
  stackedBarDataset2,
  lineData5,
  dataset1Label,
  dataset2Label,
  lineValuesLabel,
  yAxisLabel,
}) => {
  const getValues = data => {
    return data.map(v => v.value);
  };
  const getTimestamps = data => {
    return data.map(v => v.timestamp);
  };
  const datasetKeyProvider = () => {
    return Math.random();
  };

  const barLabels = Array(stackedBarDataset1.length).fill('');
  const barTimestamps = getTimestamps(stackedBarDataset1);
  const stacked1Values = getValues(stackedBarDataset1);
  const stacked2Values = getValues(stackedBarDataset2);

  const mixedBarLineData = {
    labels: barLabels,
    datasets: [
      {
        type: 'line',
        data: lineData5,
        label: lineValuesLabel,
        lineTension: 0.2,
        pointRadius: 0,
        pointHitRadius: 10,
        backgroundColor: colors.black,
        borderColor: colors.black,
        fill: false,
      },
      {
        type: 'bar',
        label: dataset1Label,
        backgroundColor: colors.red,
        hoverBackgroundColor: colors.red,
        borderColor: colors.red,
        borderWidth: 1,
        data: stacked1Values,
      },
      {
        type: 'bar',
        label: dataset2Label,
        backgroundColor: colors.greenSuccess,
        hoverBackgroundColor: colors.greenSuccess,
        borderColor: colors.greenSuccess,
        borderWidth: 1,
        data: stacked2Values,
      },
    ],
  };

  const chartOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            labelString: yAxisLabel,
            display: true,
          },
          ticks: {
            suggestedMin: 0,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          position: 'bottom',
          scaleLabel: {
            labelString: `Time Period: ${periodName}`,
            display: true,
            padding: 0,
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      callbacks: {
        afterLabel: tooltipItem => {
          return `${moment(barTimestamps[tooltipItem.index]).format(
            `DD/MM/YY${barTimestamps.length === 24 ? ', HH:mm:ss Z' : ''}`,
          )}`;
        },
      },
    },
  };

  return (
    <div className={`${styles.measurementsChart}`}>
      <Bar
        datasetKeyProvider={datasetKeyProvider}
        data={mixedBarLineData}
        options={chartOptions}
        // height={250}
      />
    </div>
  );
};

MixedChart.propTypes = {
  periodName: string.isRequired,
  stackedBarDataset1: arrayOf(
    shape({ value: number, timestamp: oneOfType([string, instanceOf(Date)]) }),
  ).isRequired,
  stackedBarDataset2: arrayOf(
    shape({ value: number, timestamp: oneOfType([string, instanceOf(Date)]) }),
  ).isRequired,
  lineData5: arrayOf(number),
  dataset1Label: string.isRequired,
  dataset2Label: string.isRequired,
  lineValuesLabel: string,
  yAxisLabel: string.isRequired,
};

export default MixedChart;
