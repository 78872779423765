import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'atoms';
import ColorPicker from '../../ColorPicker';
import { DRAWING_MODES, drawingModeType } from 'components/Geozones/types';
import useCalculateHeight from 'hooks/useCalculateHeight';
import { Select } from 'atoms';

import styles from '../geozones.module.scss';

// Temporarily hidden 2 & 3 days periods
// Ref: #184309749
const deviceUpdateFrequencyOptionsInGeozone = [
  { value: null, text: '' },
  { value: 30, text: '30 seconds' },
  { value: 300, text: '5 minutes' },
  { value: 600, text: '10 minutes' },
  { value: 1200, text: '20 minutes' },
  { value: 1800, text: '30 minutes' },
  { value: 3600, text: '1 hour' },
  { value: 7200, text: '2 hours' },
  { value: 14400, text: '4 hours' },
  { value: 21600, text: '6 hours' },
  { value: 43200, text: '12 hours' },
  { value: 86400, text: '1 day' },
  // { value: 172800, text: '2 days' },
  // { value: 259200, text: '3 days' },
];

const GeozonesEditor = ({
  name,
  color,
  onDelete,
  hasGeometry,
  drawingMode,
  onDrawingModeChange,
  refreshInterval,
  onSave,
  isFullAdmin,
}) => {
  const [editedName, setEditedName] = useState(name);
  const [editedColor, setEditedColor] = useState(color);
  const [editedFrequency, setEditedFrequency] = useState(refreshInterval);
  const [nameError, setNameError] = useState();
  const [geometryError, setGeometryError] = useState();
  const editorContainerRef = useRef();
  const editorHeight = useCalculateHeight(editorContainerRef, -10);
  const preset = {
    primaryColor: editedColor,
  };

  useEffect(() => {
    setGeometryError();
  }, [hasGeometry]);

  const saveHandler = () => {
    if (!validateFields()) {
      return;
    }

    onSave({
      name: editedName,
      color: editedColor,
      refreshInterval: editedFrequency,
    });
  };

  const validateFields = () => {
    if (!editedName) {
      const nameRequiredError = "You must provide geozone's name first";
      setNameError(nameRequiredError);
    }

    if (!hasGeometry) {
      const geometryRequiredError = "You must provide geozone's geometry";
      setGeometryError(geometryRequiredError);
    }

    return editedName && hasGeometry;
  };

  const deleteHandler = event => {
    event.preventDefault();
    onDelete();
  };

  const updateFrequencyOptions = () => {
    if (isFullAdmin) {
      return [
        { value: 1, text: 'Live Tracking' },
        ...deviceUpdateFrequencyOptionsInGeozone,
      ];
    } else {
      return deviceUpdateFrequencyOptionsInGeozone;
    }
  };

  return (
    <div>
      <span className={styles.subtitle}>
        {!!name ? 'Edit Geozone' : 'Create a new Geozone'}
      </span>
      <div
        ref={editorContainerRef}
        className="bg-white rounded border p-3"
        style={{ minHeight: editorHeight }}
      >
        <div className="form-group">
          <label>Name</label>
          <TextField
            placeholder="Geozone name"
            onChange={event => {
              setEditedName(event.target.value);
              setNameError();
            }}
            defaultValue={name}
            errors={nameError ? [nameError] : undefined}
          />
        </div>
        <div className="form-group">
          <label>Colour</label>
          <div className="w-100">
            <ColorPicker
              {...{ preset }}
              dispatchColor={color => setEditedColor(color)}
              fullWidth
            />
          </div>
        </div>
        <div className="form-group">
          <label>Mapping</label>
          <div className="d-flex">
            <button
              className={`btn btn-outline-secondary flex-grow-1 mr-1 ${
                drawingMode === DRAWING_MODES.POLYGON ? 'active' : ''
              }`}
              onClick={() => {
                onDrawingModeChange(DRAWING_MODES.POLYGON);
              }}
            >
              <i className="fal fa-draw-polygon"></i> Draw
            </button>
            <button
              className={`btn btn-outline-secondary flex-grow-1 ml-1 ${
                drawingMode === DRAWING_MODES.CIRCLE ? 'active' : ''
              }`}
              onClick={() => {
                onDrawingModeChange(DRAWING_MODES.CIRCLE);
              }}
            >
              <i className="fal fa-draw-circle"></i> Circle
            </button>
          </div>
          {geometryError && (
            <small id="drawingModeError" className="form-text text-danger">
              {geometryError}
            </small>
          )}
          {drawingMode !== DRAWING_MODES.NONE && (
            <small id="drawingModeHelp" className="form-text text-muted">
              Click on the map to start drawing
            </small>
          )}
        </div>
        <div className="form-group">
          <label>
            When devices are detected inside this Geozone, temporarily set their
            Device Update Frequency to:
          </label>
          <div className={styles.flex}>
            <Select
              id="general-tab-select"
              initialValue={refreshInterval}
              options={updateFrequencyOptions()}
              onChange={value => {
                setEditedFrequency(value);
              }}
            />
          </div>
        </div>
        <a
          href="#"
          className="text-secondary d-block my-4"
          role="button"
          onClick={deleteHandler}
        >
          <i className="far fa-trash-alt"></i> Delete Geozone
        </a>
        <button
          className={`btn btn-success ${styles.activateButton} d-block w-100`}
          onClick={saveHandler}
          disabled={nameError || geometryError}
        >
          Save Geozone
        </button>
      </div>
    </div>
  );
};

GeozonesEditor.propTypes = {
  color: PropTypes.string,
  drawingMode: drawingModeType,
  name: PropTypes.string,
  hasGeometry: PropTypes.bool,
  onDelete: PropTypes.func,
  onDrawingModeChange: PropTypes.func,
  refreshInterval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSave: PropTypes.func,
  isFullAdmin: PropTypes.bool,
};

export default GeozonesEditor;
