import React, { useState } from 'react';
import axios from 'axios';
import useSWR, { mutate } from 'swr';
import moment from 'moment';

const useEventsTable = events => {
  const eventTypes = getUniqueEventTypes(events);
  const [selectedTypes, setTypes] = useState([]);

  const onTypeClick = eventType => {
    if (selectedTypes.includes(eventType)) {
      const newSelectedTypes = [...selectedTypes];
      newSelectedTypes.splice(newSelectedTypes.indexOf(eventType), 1);
      setTypes(newSelectedTypes);
    } else {
      setTypes([...selectedTypes, eventType]);
    }
  };

  const onClearLogs = async id => {
    const endpointUrl = `${process.env.API_URL}/devices/${id}/logs/clear`;

    try {
      await axios.post(endpointUrl);
    } catch (error) {
      setError(error.message);
    }
  };

  function getUniqueEventTypes(events) {
    return Array.from(new Set(events.map(event => event.type)));
  }

  function getButtonClass(eventType) {
    const colorName = getEventColor(eventType);

    return selectedTypes.includes(eventType)
      ? `btn-${colorName}`
      : `btn-outline-${colorName}`;
  }

  function getBadgeClass(eventType) {
    return `badge-${getEventColor(eventType)}`;
  }

  function getEventColor(eventType) {
    const eventColors = {
      boot: 'pink',
      check_in: 'info',
      location: 'success',
      battery_level: 'primary',
      fail_to_connect: 'light-gray',
      drop: 'danger',
      open_box: 'warning',
      local_items: 'secondary',
      other: 'purple',
    };

    return eventColors[eventType] || eventColors['other'];
  }

  return {
    eventTypes,
    selectedTypes,
    onTypeClick,
    getButtonClass,
    getBadgeClass,
    onClearLogs,
  };
};

export default useEventsTable;
