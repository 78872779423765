import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'atoms';
import { eventsType } from '../types';
import { EventsList } from './EventsList/EventsList';
import moment from 'moment';

const title = (
  <span>
    <i className="fas fa-history"></i> Activity
  </span>
);

const EventDataModal = ({
  events = [],
  deviceId,
  clearEventsList,
  period,
  ...rest
}) => (
  <Modal {...rest} title={title}>
    <EventsList {...{ events, deviceId, clearEventsList, period }}></EventsList>
  </Modal>
);

EventDataModal.propTypes = {
  events: eventsType,
  deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearEventsList: PropTypes.func,
  period: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
  ),
};

export default EventDataModal;
