import React, { useState } from 'react';
import { SWRConfig } from 'swr';
import { fetchApi } from 'utils';
import { initialDataType } from './types';
import axios from 'axios';
import Geozones from './Geozones';
import styles from './geozones.module.scss';

export const APILinkedGeozones = ({
  initialData: { geozones, isFullAdmin },
}) => {
  const [loadedGeozones, setLoadedGeozones] = useState(geozones);
  const [isLoading, setIsLoading] = useState(false);

  function updateGeozoneData(newGeozoneObj, remove = false) {
    const currentGeozoneIndex = loadedGeozones.findIndex(
      zone => zone.id === newGeozoneObj.id,
    );

    const isZoneExistAlready = currentGeozoneIndex !== -1;

    if (remove) {
      deleteZone();
    } else if (isZoneExistAlready) {
      updateZone();
    } else {
      addNewZone();
    }

    function addNewZone() {
      setIsLoading(true);
      const endpointUrl = `${process.env.API_URL}/geozones`;
      axios
        .post(endpointUrl, newGeozoneObj)
        .then(response => {
          newGeozoneObj.id = response.data.id;
          setLoadedGeozones([...loadedGeozones, newGeozoneObj]);
          setTimeout(() => {
            setIsLoading(false);
          }, 350);
        })
        .catch(error => {
          setIsLoading(false);
          alert('Unable to create such geozone');
        });
    }

    function updateZone() {
      setIsLoading(true);
      const endpointUrl = `${process.env.API_URL}/geozones/${newGeozoneObj.id}`;
      axios
        .put(endpointUrl, newGeozoneObj)
        .then(() => {
          loadedGeozones[currentGeozoneIndex] = newGeozoneObj;
          setLoadedGeozones([...loadedGeozones]);
          setTimeout(() => {
            setIsLoading(false);
          }, 350);
        })
        .catch(error => {
          setIsLoading(false);
          alert('Unable to update such geozone');
        });
    }

    function deleteZone() {
      const CONFIRMATION_MESSAGE = 'The geozone will be deleted. Are You sure?';
      const endpointUrl = `${process.env.API_URL}/geozones/${newGeozoneObj.id}`;

      if (!isZoneExistAlready) {
        return;
      }

      if (confirm(CONFIRMATION_MESSAGE)) {
        setIsLoading(true);
        axios
          .delete(endpointUrl)
          .then(() => {
            const filteredLoadedGeozones = [...loadedGeozones];
            filteredLoadedGeozones.splice(currentGeozoneIndex, 1);
            setLoadedGeozones(filteredLoadedGeozones);
            setTimeout(() => {
              setIsLoading(false);
            }, 350);
          })
          .catch(error => {
            setIsLoading(false);
            alert(error.message);
          });
      }
    }
  }

  return (
    <SWRConfig
      value={{
        fetcher: fetchApi,
      }}
    >
      {isLoading ? (
        <div className="text-center">
          <div className={`${styles.ldsDualRing}`}></div>
          <p>Loading...</p>
        </div>
      ) : (
        <Geozones
          updateGeozoneData={updateGeozoneData}
          loadedGeozones={loadedGeozones}
          isFullAdmin={isFullAdmin}
        />
      )}
    </SWRConfig>
  );
};

APILinkedGeozones.propTypes = {
  initialData: initialDataType,
};

export default props => <APILinkedGeozones {...props} />;
