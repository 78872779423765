import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Select = ({
  id,
  options,
  initialValue,
  onChange,
  defaultOptionText,
  currentSelectValue,
  ...rest
}) => {
  const [state, setState] = useState(initialValue || '');

  useEffect(() => {
    if (currentSelectValue) {
      setState(currentSelectValue);
    }
  }, [currentSelectValue]);

  return (
    <select
      {...rest}
      value={state}
      onChange={event => {
        const newValue = event.target.value;
        setState(newValue);
        onChange(newValue);
      }}
    >
      {options.map(({ value, text }, index) => (
        <option key={`${id}-option-${index}`} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      text: PropTypes.any,
    }),
  ),
  defaultOptionText: PropTypes.string,
  currentSelectValue: PropTypes.any,
};

Select.defaultProps = {
  className: 'form-control',
  options: [],
  onChange: () => {},
};

export default Select;
