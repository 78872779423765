import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import DevicesTable from './components/DevicesTable';
import DevicesMap from './components/DevicesMap';
import DashboardTabs from './components/tabs/DashboardTabs';
import RailsNavigation from './components/RailsNavigation';
import ExpandableRowComponent from '../../atoms/Tables/ExpandableTable/ExpandableRowComponent';
import { MapNav } from './MapNav';
import useTrackingDashbord from './useTrackingDashboard';
import { initialDataType } from './types';
import { useMediaQuery } from '../../hooks';
import breakpoints from '../../../assets/stylesheets/utils/variables.scss';

import styles from './trackingDashboard.module.scss';

const TrackingDashboard = ({
  initialData: {
    boxOpenInfo,
    contextualData,
    devices,
    ui,
    routes,
    isFullAdmin,
  },
}) => {
  const [tableHeight, setTableHeight] = useState(0);
  const {
    tableData,
    mapData,
    searchFilter,
    filterDevices,
    getRowsData,
    onPointerClick,
    showMap,
    showDevicesList,
    mapLayer,
    expandedDevice,
    setColumnsNumber,
    columnsNumber,
    deviceIsReady,
    deviceLoaded,
    deviceNotLoaded,
  } = useTrackingDashbord(devices);

  const matches = useMediaQuery(`(min-width: ${breakpoints.mobileMinWidth})`);

  const deviceDetailRef = useRef(null);

  const getTableHeight = () => {
    const { current } = deviceDetailRef;
    return current ? current.clientHeight : 0;
  };

  useEffect(() => {
    setTableHeight(getTableHeight());
  }, [deviceDetailRef, expandedDevice, deviceIsReady]);

  return (
    <>
      <RailsNavigation
        ui={ui}
        mapLayer={mapLayer}
        showDevicesList={showDevicesList}
        showMap={showMap}
        filterDevices={filterDevices}
      />
      <div className={`${styles.dashboardContainer}`}>
        <h1 className="page-title page-title--hide">Devices</h1>
        <div className={`${styles.dashboardRow} ${styles.dashboardColumns}`}>
          {matches ? (
            <>
              <div className={`${styles.dashboardCol} ${styles.tableCol}`}>
                <DevicesTable
                  {...{
                    boxOpenInfo,
                    data: tableData,
                    getRowsData,
                    setColumnsNumber,
                    searchFilter,
                    isFullAdmin,
                  }}
                />
              </div>
              <div className={`${styles.dashboardCol} ${styles.mapCol}`}>
                <MapNav
                  filterFn={filterDevices}
                  activateDeviceRoute={routes.activateDevice}
                />
                <div className={`${styles.map}`}>
                  <DevicesMap
                    {...{
                      data: mapData,
                      contextualData,
                      onPointerClick,
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={`${mapLayer ? 'invisible ' : 'visible '} ${
                  styles.dashboardCol
                } ${styles.tableColMobile}`}
              >
                <DevicesTable
                  {...{
                    boxOpenInfo,
                    data: tableData,
                    getRowsData,
                    setColumnsNumber,
                    showMap,
                    singleExpand: true,
                    mapLayer,
                    searchFilter,
                  }}
                />
              </div>
              <div
                className={`${mapLayer ? 'visible ' : 'invisible '} ${
                  styles.dashboardCol
                } ${styles.mapCol}`}
              >
                <div style={{ height: `calc(100% - ${tableHeight}px)` }}>
                  <DevicesMap
                    {...{ data: mapData, contextualData, onPointerClick }}
                  />
                </div>
                <div
                  style={{ zIndex: expandedDevice ? '0' : '-1' }}
                  className={styles.tableWrapper}
                  ref={deviceDetailRef}
                >
                  <table className={styles.deviceTable}>
                    <tbody>
                      <ExpandableRowComponent
                        row={expandedDevice}
                        columnsNumber={columnsNumber}
                        renderRowSubComponent={DashboardTabs}
                        mapAndDevice
                        showDevicesList={showDevicesList}
                        mapLayer
                        onDeviceLoaded={deviceLoaded}
                        onDeviceNotLoaded={deviceNotLoaded}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

TrackingDashboard.propTypes = {
  initialData: initialDataType,
};

export default props => <TrackingDashboard {...props} />;
