import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { string, func } from 'prop-types';

import { dataType, configOptionsType } from './types';

import colors from '../../../assets/stylesheets/_colors.scss';

const animatedComponents = makeAnimated();

export const MultiSelect = ({
  options,
  onChange,
  currentValues,
  initialValues = [],
  placeholder = '',
  className = '',
  configOptions = {
    isMulti: true,
    closeMenuOnSelect: false,
    isSearchable: true,
  },
}) => {
  const customStyles = {
    control: (provided, { isFocused }) => {
      return {
        ...provided,
        boxShadow: isFocused ? colors.blueBoxShadow : null,
      };
    },
    container: (provided, state) => ({
      ...provided,
      minWidth: 150,
    }),
    option: styles => {
      return {
        ...styles,
        cursor: 'pointer',
      };
    },
  };

  const customTheme = theme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.blueFocusBorder,
        neutral20: colors.grayBorder,
        neutral50: colors.graySecondary,
      },
    };
  };

  return (
    <Select
      value={currentValues}
      defaultValue={initialValues}
      options={options}
      isMulti={configOptions.isMulti}
      styles={customStyles}
      theme={customTheme}
      components={animatedComponents}
      isSearchable={configOptions.isSearchable}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      closeMenuOnSelect={configOptions.closeMenuOnSelect}
    />
  );
};

MultiSelect.propTypes = {
  options: dataType.isRequired,
  onChange: func.isRequired,
  currentValues: dataType,
  initialValues: dataType,
  placeholder: string,
  className: string,
  configOptions: configOptionsType,
};
