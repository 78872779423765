import React from 'react';
import { func, number, shape, string } from 'prop-types';
import { MultiSelect } from '../../../../atoms/MultiSelect/MultiSelect';

const dateRanges = [
  { value: 0, label: 'Today' },
  { value: 1, label: 'Yesterday' },
  { value: 2, label: 'Last 7 Days' },
  { value: 3, label: 'Last 30 Days' },
  { value: 4, label: 'Last 90 Days' },
  { value: 5, label: 'Year to Date' },
];

export const DateRange = ({ dateRange, setDateRange, className }) => {
  return (
    <MultiSelect
      currentValues={dateRange}
      initialValues={dateRange}
      options={dateRanges}
      configOptions={{
        isMulti: false,
        closeMenuOnSelect: true,
        isSearchable: true,
      }}
      placeholder="Date Range"
      onChange={selectedValue => setDateRange(selectedValue)}
      className={className}
    />
  );
};

DateRange.propTypes = {
  setDateRange: func.isRequired,
  dateRange: shape({
    value: number,
    label: string,
  }),
  className: string,
};
