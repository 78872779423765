import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let fields = [...this.element.querySelectorAll('input')];
    fields
      .filter(field => field.type !== 'hidden' && field.type !== 'submit')
      .forEach(field => {
        field.addEventListener('input', () => {
          if (field === document.activeElement) {
            if (field.classList.contains('is-invalid')) {
              field.classList.remove('is-invalid');
              if (field.type === 'checkbox') {
                // do nothing with checkbox
              } else if (field.dataset.name === 'device-imei') {
                field.parentNode.parentNode.parentNode.nextElementSibling.style.display =
                  'none';
              } else {
                field.nextElementSibling.style.display = 'none';
              }
            }
            if (field.type === 'password') {
              if (field.classList.contains('is-valid')) {
                field.classList.remove('is-valid');
              }
            }
          }
        });
      });
  }
}
