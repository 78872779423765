import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import useUpload from 'atoms/Uploader/useUpload';
import { uploaderType } from 'atoms/Uploader/Uploader';
import { DEFAULT_RESTRICTIONS } from 'atoms/Uploader/uploader.constants';
import Select from 'atoms/Select/Select';
import Tooltip from 'atoms/tooltip/tooltip';

import styles from './digitalLabelUploader.module.scss';

const FETCH_LABEL_URL = `${process.env.API_URL}/shipment_carriers/fetch_label`;

const DigitalLabelUploader = ({ deviceId, carrier, uploader, onUpload }) => {
  const [uploadLabelError, setUploadLabelError] = useState(null);
  const [retrieveLabelError, setRetrieveLabelError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUploadError = error => {
    setUploadLabelError(error.message);
  };

  const { progress, handleInputChange } = useUpload({
    uploader,
    restrictions: DEFAULT_RESTRICTIONS,
    onError: handleUploadError,
    onFinish: onUpload,
  });

  const handleSubmit = async event => {
    event.preventDefault();

    setIsSubmitting(true);
    setRetrieveLabelError(null);

    const form = event.target;
    const formData = new FormData(form);

    const trackingCode = formData.get('trackingCode');
    const carrierId = formData.get('carrierId');

    if (!trackingCode || !carrierId) {
      setRetrieveLabelError('Please fill in all fields');
      setIsSubmitting(false);

      return;
    }

    try {
      await axios.get(
        `${FETCH_LABEL_URL}?tracking_code=${trackingCode}&device_id=${deviceId}&id=${carrierId}`,
      );
      await onUpload();
    } catch (error) {
      switch (error.response.status) {
        case 404:
          setRetrieveLabelError('Label not found');
          break;
        case 401:
          setRetrieveLabelError(
            'Wrong credentials. Please check your settings',
          );
          break;
        default:
          setRetrieveLabelError('An error occurred. Please try again');
      }
    }

    setIsSubmitting(false);
  };

  const carrierOptions = carrier.map(carrier => ({
    value: carrier.id,
    text: carrier.carrierType,
  }));

  return (
    <div className={styles.container}>
      <label
        htmlFor={progress > 0 ? '' : uploader.inputId}
        className={styles.card}
        style={{
          cursor: progress > 0 ? 'default' : 'pointer',
        }}
      >
        <div className={styles.cardContainer}>
          <div>
            <h3 className={styles.cardTitle}>
              Upload a shipping label{' '}
              <Tooltip content="Any .Pdf, jpg, jpeg, png, gif, bmp. Maximum file size 2mb. 4 x 6 Aspect Ratio.">
                <i className="fa-light fa-circle-info" />
              </Tooltip>
            </h3>
            <p className={styles.cardDescription}>From PDF or Image File</p>
          </div>
          {progress == 0 ? (
            <i className="fa-light fa-upload" style={{ fontSize: 24 }} />
          ) : (
            <i
              className="fa-light fa-spinner fa-spin"
              style={{ fontSize: 24 }}
            />
          )}
          {uploadLabelError && (
            <p className={styles.errorMessage}>{uploadLabelError}</p>
          )}
        </div>
        <input
          id={uploader.inputId}
          type="file"
          hidden
          name={`${uploader.objectName}[${uploader.attributeName}]`}
          onChange={handleInputChange}
        />
      </label>
      <div className={styles.card}>
        <h3 className={styles.cardTitle}>
          Retrieve label from pre-booked shipment{' '}
          <Tooltip content="To enable this feature, please add your shipment carrier credentials to Settings / Shipment Carriers.">
            <i className="fa-light fa-circle-info" />
          </Tooltip>
        </h3>
        <form className={styles.cardForm} onSubmit={handleSubmit}>
          {retrieveLabelError && (
            <p className={styles.errorMessage}>{retrieveLabelError}</p>
          )}
          <Select
            name="carrierId"
            options={carrierOptions}
            className={styles.cardInput}
          />
          <input
            name="trackingCode"
            className={styles.cardInput}
            placeholder="Tracking Code"
          />
          <button
            disabled={isSubmitting}
            className={styles.cardButton}
            type="submit"
          >
            Retrieve label
            {isSubmitting && <i className="fa fa-spinner fa-spin ml-1" />}
          </button>
        </form>
      </div>
    </div>
  );
};

DigitalLabelUploader.propTypes = {
  deviceId: PropTypes.string,
  carrier: PropTypes.array,
  uploader: uploaderType,
  onUpload: PropTypes.func,
};

export default DigitalLabelUploader;
