import React from 'react';
import colors from '../../../assets/stylesheets/_colors.scss';
import styles from './statusLight.module.scss';
import PropTypes from 'prop-types';
import { capitalize } from 'utils';
import { DEVICE_STATUSES } from './../../components/DeviceView/types';

export const StatusLight = ({ status, withText }) => {
  const getBgStyle = status => {
    const statusesColors = {
      default: colors.lightGrey,
      inactive: colors.lightGrey,
      good: colors.lightGreen,
      warning: colors.darkOrange,
      problem: 'red',
    };

    return {
      backgroundColor: statusesColors[status] || statusesColors.default,
    };
  };

  return (
    <div className={styles.statusLightContainer}>
      <div className={styles.statusLightIcon} style={getBgStyle(status)}></div>
      {withText && (
        <span className={styles.statusLightStatus}>
          <strong>Status: </strong>
          {capitalize(status)}
        </span>
      )}
    </div>
  );
};

StatusLight.propTypes = {
  status: PropTypes.oneOf(DEVICE_STATUSES),
  withText: PropTypes.bool,
};

export default StatusLight;
