import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, MeasurementsChart, MixedChart } from 'atoms';
import { measurmentsType } from '../../types';
import styles from '../../deviceView.module.scss';
import moment from 'moment';

const DEFAULT_PERIOD = `${moment().format('DD/MM/YY')} to ${moment()
  .subtract(7, 'day')
  .format('DD/MM/YY')}`;

// uncommenting 'Days Box Opened' will place 'All G-Force Events' above 'Highest G-Force Exposure' not below in dropdown
const initialDropdownOptions = [
  // Temporarily hide data
  // { value: 1, text: 'Days Box Opened' },
  { value: 2, text: 'Highest G-Force Exposure' },
  { value: 4, text: 'Average Temperature Exposure' },
  { value: 5, text: 'Battery Level' },
  { value: 6, text: 'Light Level' },
  // 7 is reserved for 'Device Connections'
  // 8 is reserved for 'GPS TTF & TCD'
  // 9 is reserved for 'GPS Uncertitude'
  // 10 is reserved for 'Power Consumption'
  // 11 is reserved for 'Data summary'
];

const gForceEventsOptionValue = 3;

const MeasurmentsTimeline = ({ data = [], isFullAdmin, selectedPeriod }) => {
  const [dropdownOptions, setDropdownOptions] = useState(
    initialDropdownOptions,
  );
  const [selectedDataType, setDataType] = useState(
    initialDropdownOptions[0].value,
  );
  const [currentSelectValue, setCurrentSelectValue] = useState(null);

  const isTheSameDay = useMemo(() => {
    if (
      typeof selectedPeriod[0] === 'string' &&
      typeof selectedPeriod[1] === 'string'
    ) {
      return selectedPeriod[0] === selectedPeriod[1];
    }

    const startDate = selectedPeriod[0]._i;
    const endDate = selectedPeriod[1]._i;

    const newStartDate = JSON.stringify(startDate);
    const newEndDate = JSON.stringify(endDate);

    return newStartDate === newEndDate;
  }, [selectedPeriod[0], selectedPeriod[1]]);

  useEffect(() => {
    if (isTheSameDay) {
      const gForceEventOption = dropdownOptions.find(
        option => option.value === gForceEventsOptionValue,
      );
      if (!gForceEventOption) {
        const newOptions = [...dropdownOptions];
        newOptions.splice(1, 0, {
          value: gForceEventsOptionValue,
          text: 'All G-Force Events',
        });
        setDropdownOptions(newOptions);
        setCurrentSelectValue(null);
      }
    } else {
      const gForceEventOption = dropdownOptions.find(
        option => option.value === gForceEventsOptionValue,
      );
      if (gForceEventOption) {
        const filteredOptions = dropdownOptions.filter(
          option => option.value !== gForceEventsOptionValue,
        );
        setDropdownOptions(filteredOptions);
        if (selectedDataType === `${gForceEventsOptionValue}`) {
          setDataType(`${dropdownOptions[0].value}`);
          setCurrentSelectValue(`${dropdownOptions[0].value}`);
        }
      }
    }

    const connectionFailureOption = dropdownOptions.find(
      option => option.value === 7,
    );

    if (isFullAdmin && !connectionFailureOption) {
      setDropdownOptions([
        ...dropdownOptions,
        { value: 7, text: '[ADMIN] Device Connections' },
        { value: 8, text: '[ADMIN] GPS TTF & TCD' },
        { value: 9, text: '[ADMIN] GPS Uncertitude' },
        { value: 10, text: '[ADMIN] Power Consumption' },
        { value: 11, text: '[ADMIN] Data Summary' },
      ]);
    }
  }, [isTheSameDay, dropdownOptions, selectedDataType]);

  const renderChart = chartTypeIndex => {
    const charts = {
      1: {
        data: data.opened,
        yAxisLabel: 'Opened / Not Opened',
        valuesLabel: 'Box opened',
      },
      2: {
        data: data.gForce,
        yAxisLabel: 'G Force',
        valuesLabel: 'G Force',
      },
      3: {
        data: data.gForceEvents,
        yAxisLabel: 'G Force Value',
        valuesLabel: 'G Force Value',
      },
      4: {
        data: data.temperature,
        yAxisLabel: 'Temperature',
        valuesLabel: 'Temperature',
      },
      5: {
        data: data.batteryLevel,
        yAxisLabel: 'Battery Level (%)',
        valuesLabel: 'Battery Level',
      },
      6: {
        data: data.lightLevel,
        yAxisLabel: 'Light Level',
        valuesLabel: 'Light Level',
      },
      7: {
        data: data.connectionFailure,
        yAxisLabel: 'Connect Events',
        valuesLabel: 'Connect Events',
      },
      9: {
        data: data.uncertitude,
        yAxisLabel: 'Median Uncertitude (meters)',
        valuesLabel: 'Median Uncertitude',
      },
      10: {
        data: data.powerConsumption,
        yAxisLabel: 'Median PCDC (%)',
        valuesLabel: 'Median PCDC',
      },
    };
    const chartToDisplayProps = charts[Number(chartTypeIndex)];

    if (chartTypeIndex === '11') {
      return (
        <div className={`${styles.summaryChart}`}>
          <table className={`${styles.summaryTable}`}>
            <tbody>
              <tr>
                <td colSpan="2">
                  <b>Connectivity</b>
                </td>
              </tr>
              <tr>
                <td>Successful Connections:</td>
                <td>{data.connected.successful}</td>
              </tr>
              <tr>
                <td>Failed Connections:</td>
                <td>{data.connected.failed}</td>
              </tr>
              <tr className={`${styles.spaceUnder}`}>
                <td>Connection Success Rate:</td>
                <td>{`${data.summary.connectionSuccessRate}%`}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Location Events</b>
                </td>
              </tr>
              <tr>
                <td>Total GPS Location Events:</td>
                <td>{data.summary.locationData.totalGpsLocationEvents || 0}</td>
              </tr>
              <tr className={`${styles.spaceUnder}`}>
                <td>Total Geolocation Events:</td>
                <td>{data.summary.locationData.totalGeolocationEvents || 0}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Location Data Quality</b>
                </td>
              </tr>
              <tr>
                <td>GPS Median Time to Fix:</td>
                <td>{`${data.summary.locationData.gpsMedianTimeToFix ||
                  0}s`}</td>
              </tr>
              <tr>
                <td>GPS Median Uncertitude:</td>
                <td>{`${data.summary.locationData.gpsMedianUncertitude ||
                  0}m`}</td>
              </tr>
              <tr>
                <td>GPS Median Fixes:</td>
                <td>{`${data.summary.locationData.gpsMedianFixes || 0}`}</td>
              </tr>
              <tr className={`${styles.spaceUnder}`}>
                <td>Geolocation Median Uncertitude:</td>
                <td>{`${data.summary.locationData
                  .geolocationMedianUncertitude || 0}m`}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Selected Location Event (Trust & Accuracy)</b>
                </td>
              </tr>
              <tr>
                <td>GPS Location Events:</td>
                <td>{`${data.summary.locationData.gpsLocationEventsPerc ||
                  0}%`}</td>
              </tr>
              <tr className={`${styles.spaceUnder}`}>
                <td>Geolocation Events:</td>
                <td>{`${data.summary.locationData.geolocationEventsPerc ||
                  0}%`}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Radio Technology</b>
                </td>
              </tr>
              <tr>
                <td>0 GSM:</td>
                <td>{`${data.summary.radioData[0].total || 0} (${data.summary
                  .radioData[0].perc || 0}%)`}</td>
              </tr>
              <tr>
                <td>3 GSM/GPRS with EDGE availability:</td>
                <td>{`${data.summary.radioData[3].total || 0} (${data.summary
                  .radioData[3].perc || 0}%)`}</td>
              </tr>
              <tr>
                <td>7 LTE:</td>
                <td>{`${data.summary.radioData[7].total || 0} (${data.summary
                  .radioData[7].perc || 0}%)`}</td>
              </tr>
              {/* Radio type 8 is not supported for the moment  */}
              {/* Ref: https://www.pivotaltracker.com/story/show/184120965 */}
              {/* <tr>
                <td>8 EC-GSM-IoT (A/Gb mode):</td>
                <td>{`${data.summary.radioData[8].total || 0} (${data.summary
                  .radioData[8].perc || 0}%)`}</td>
              </tr> */}
              <tr>
                <td>9 E-UTRAN (NB-S1 mode):</td>
                <td>{`${data.summary.radioData[9].total || 0} (${data.summary
                  .radioData[9].perc || 0}%)`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (chartTypeIndex === '7') {
      return (
        <MixedChart
          periodName={
            selectedPeriod
              ? selectedPeriod
                  .map(date => moment(date).format('DD/MM/YY'))
                  .join(' to ')
              : DEFAULT_PERIOD
          }
          yAxisLabel={'Connections'}
          stackedBarDataset1={data.connectionFailure}
          stackedBarDataset2={data.connectionSuccess}
          lineData5={data.connectionSuccessRate}
          dataset1Label="Failed Connections"
          dataset2Label="Successful Connections"
          lineValuesLabel="Connection success rate (%)"
        />
      );
    } else if (chartTypeIndex === '8') {
      return (
        <MixedChart
          periodName={
            selectedPeriod
              ? selectedPeriod
                  .map(date => moment(date).format('DD/MM/YY'))
                  .join(' to ')
              : DEFAULT_PERIOD
          }
          yAxisLabel={'Median TCD (seconds)'}
          stackedBarDataset1={data.gpsTimeToFix}
          stackedBarDataset2={data.gpsTcdTtfDifference}
          dataset1Label="TTF"
          dataset2Label="TCD - TTF"
        />
      );
    } else {
      return (
        <MeasurementsChart
          {...{ ...chartToDisplayProps }}
          periodName={
            selectedPeriod
              ? selectedPeriod
                  .map(date => moment(date).format('DD/MM/YY'))
                  .join(' to ')
              : DEFAULT_PERIOD
          }
        />
      );
    }
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-between mb-2">
        <span className="mr-3">
          <strong>Timeline</strong>
        </span>
        <Select
          id="chart-data-type"
          options={dropdownOptions}
          style={{ maxWidth: 'fit-content' }}
          initialValue={initialDropdownOptions[0].value}
          onChange={setDataType}
          currentSelectValue={currentSelectValue}
        />
      </div>
      <div className="card">{renderChart(selectedDataType)}</div>
    </>
  );
};

MeasurmentsTimeline.propTypes = {
  data: measurmentsType,
  isFullAdmin: PropTypes.bool,
  selectedPeriod: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
  ),
};

export default MeasurmentsTimeline;
