import PropTypes from 'prop-types';

const {
  shape,
  number,
  string,
  oneOf,
  arrayOf,
  instanceOf,
  oneOfType,
  bool,
  object,
} = PropTypes;

export const DEVICE_STATUSES = ['inactive', 'good', 'warning', 'problem'];
export const deviceStatusType = oneOf(DEVICE_STATUSES);

export const eventType = shape({
  type: string,
  timestamp: oneOfType([string, instanceOf(Date)]),
  data: shape({
    latitude: number,
    longitude: number,
  }),
});

export const eventsType = arrayOf(eventType);

export const measurmentsType = shape({
  opened: arrayOf(bool),
  gForce: arrayOf(
    shape({
      value: oneOfType([number, string]),
      timestamp: string,
    }),
  ),
  temperature: arrayOf(
    shape({
      value: oneOfType([number, string]),
      timestamp: string,
    }),
  ),
  batteryLevel: arrayOf(
    shape({
      value: oneOfType([number, string]),
      timestamp: string,
    }),
  ),
  lightLevel: arrayOf(
    shape({
      value: bool,
      timestamp: string,
    }),
  ),
  connectionFailure: arrayOf(
    shape({
      value: oneOfType([number, string]),
      timestamp: string,
    }),
  ),
});

export const deviceType = shape({
  id: oneOfType([number, string]),
  name: string,
  status: deviceStatusType,
  events: eventsType,
  measurments: measurmentsType,
});

export const initialDataType = shape({
  device: deviceType,
  contextualData: object,
  routes: shape({
    dashboard: string,
  }),
  userName: string,
});
