import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';
import colors from '../../../assets/stylesheets/_colors.scss';
import styles from '../../components/DeviceView/deviceView.module.scss';

export const MeasurementsChart = ({
  periodName,
  data = [],
  yAxisLabel = '',
  valuesLabel = '',
}) => {
  const labels = Array(data.length).fill('');
  const values = data.map(v => v.value);
  const timestamps = data.map(v => v.timestamp);

  const chartBarData = {
    labels: labels,
    datasets: [
      {
        label: valuesLabel,
        backgroundColor: colors.red,
        hoverBackgroundColor: colors.red,
        borderColor: colors.lightGrey,
        borderWidth: 1,
        data: values,
      },
    ],
  };

  const chartLineData = {
    labels: labels,
    datasets: [
      {
        label: valuesLabel,
        fill: false,
        lineTension: valuesLabel === 'Temperature' ? 0.5 : 0.1,
        backgroundColor: colors.greenSuccess,
        borderColor: colors.greenSuccess,
        pointBorderColor: colors.lightGrey,
        pointBackgroundColor: '#fff',
        pointHoverBackgroundColor: colors.lightGrey,
        pointHoverBorderColor: colors.greenSuccess,
        pointHitRadius: 10,
        spanGaps: true,
        data: values,
        steppedLine: valuesLabel === 'Light Level',
      },
    ],
  };

  const hasBooleanValues = values.some(value => typeof value === 'boolean');

  const chartProps = {
    options: {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              labelString: yAxisLabel,
              display: true,
            },
            ticks: {
              callback: hasBooleanValues ? () => '' : value => value,
              precision: 3,
              suggestedMin: 0,
            },
          },
        ],
        xAxes: [
          {
            position: 'bottom',
            scaleLabel: {
              labelString: `Time Period - ${periodName}`,
              display: true,
              padding: 0,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: tooltipItem => {
            return `${valuesLabel}: ${values[tooltipItem.index]}`;
          },
          afterLabel: tooltipItem => {
            return `${moment(timestamps[tooltipItem.index])
              .local()
              .format(
                `DD/MM/YY${
                  timestamps[tooltipItem.index].length > 20 ? ', HH:mm:ss' : ''
                }`,
              )}`;
          },
        },
      },
    },
    legend: {
      display: false,
    },
  };

  if (valuesLabel === 'Light Level') {
    chartProps.options.scales.yAxes = [
      {
        scaleLabel: {
          labelString: yAxisLabel,
          display: true,
        },
        ticks: {
          callback: v => {
            if (v == 1) {
              return 'Box Open';
            } else if (v == 0) {
              return 'Box Closed';
            }
          },
        },
      },
    ];
  }

  if (valuesLabel === 'G Force Value') {
    let yAxesOptions = chartProps.options.scales.yAxes[0];

    yAxesOptions = {
      ...yAxesOptions,
      ticks: { ...yAxesOptions.ticks, suggestedMin: 0 },
    };

    chartProps.options.scales.yAxes[0] = yAxesOptions;
  }

  const chartBarProps = {
    data: chartBarData,
    width: 100,
    height: 200,
    ...chartProps,
  };

  const chartLineProps = {
    data: chartLineData,
    ...chartProps,
  };

  return (
    <div className={`${styles.measurementsChart}`}>
      {[
        'G Force',
        'Box opened',
        'G Force Value',
        'Fail to Connect Events',
        'Median Uncertitude',
        'Median TTF',
        'Median PCDC',
      ].includes(valuesLabel) ? (
        <Bar {...chartBarProps} />
      ) : (
        <Line {...chartLineProps} />
      )}
    </div>
  );
};

MeasurementsChart.propTypes = {
  periodName: PropTypes.string,
  data: PropTypes.array,
  yAxisLabel: PropTypes.string,
  valuesLabel: PropTypes.string,
};

export default MeasurementsChart;
