import React from 'react';
import PropTypes from 'prop-types';

import styles from './digitalLabelView.module.scss';

const DigitalLabelView = ({
  currentLabelFileName,
  currentLabelUrl,
  pendingLabelFileName,
  pendingLabelUrl,
}) => {
  return (
    <div className={styles.labelsContainer}>
      {currentLabelUrl && (
        <div className={styles.labelWrapper}>
          <p className={styles.fileName}>
            Existing Label: <span>{currentLabelFileName}</span>
          </p>
          <div className={styles.imageWrapper}>
            <img
              className={styles.image}
              src={currentLabelUrl}
              alt={currentLabelFileName}
            />
          </div>
        </div>
      )}
      {pendingLabelUrl && (
        <div className={styles.labelWrapper}>
          <p className={styles.fileName}>
            Pending Label: <span>{pendingLabelFileName}</span>
          </p>
          <div className={styles.imageWrapper}>
            <img
              className={styles.image}
              src={pendingLabelUrl}
              alt={pendingLabelFileName}
            />
            <div className={styles.imageOverflow}>
              <i className="fal fa-clock" style={{ fontSize: 24 }}></i>
              <p>Label will update on the device shortly.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DigitalLabelView.propTypes = {
  currentLabelFileName: PropTypes.string,
  currentLabelUrl: PropTypes.string,
  pendingLabelFileName: PropTypes.string,
  pendingLabelUrl: PropTypes.string,
};

export default DigitalLabelView;
