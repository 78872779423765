import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  get time() {
    return this.data.get('time');
  }

  connect() {
    const element = this.formTarget;
    $(element).on('ajax:success', (e, data) => {
      this.showMessage(e, data);
      this.formTarget.innerHTML = data.html;
    });
  }

  showMessage(e, data) {
    if (data.status == 'ok') {
      e.target.style.display = 'none';
      e.target.parentNode.innerHTML =
        '<p class="panel__info panel__info--success">' + data.message + '</p>';
      setTimeout(function() {
        window.location.href = data.redirect;
      }, this.time);
    } else {
      if ($('.panel__info').length > 0) {
        $('.panel__info').html(data.message);
      } else {
        $(e.target)
          .parent()
          .prepend('<p class="panel__info">' + data.message + '</p>');
      }
    }
  }
}
