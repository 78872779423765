import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';
import TableCore from '../TableCore/TableCore';

import BasicRowComponent from './BasicRowComponent';

const Table = ({
  columns: userColumns,
  data,
  emptyComponent,
  paginationClassName,
  ...rest
}) => {
  const tableProps = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        pageSize: 20,
      },
    },
    usePagination,
  );

  return (
    <TableCore
      {...{ tableProps, emptyComponent, paginationClassName, ...rest }}
      rowComponent={BasicRowComponent}
    />
  );
};

Table.propTypes = {
  className: PropTypes.string,
  paginationClassName: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  emptyComponent: PropTypes.element,
};

Table.defaultProps = {
  className: 'table',
  paginationClassName: '',
  columns: [],
  data: [],
};

export default Table;
