import React, { useEffect, useState } from 'react';

const useInitialDimensions = containerRef => {
  const [containerDimensions, setContainerWidthHeight] = useState({
    width: 'auto',
    height: 'auto',
  });

  useEffect(() => {
    setContainerWidthHeight({
      width: containerRef.current.clientWidth,
      height: containerRef.current.clientHeight,
    });
  }, []);

  return { containerDimensions };
};

export default useInitialDimensions;
