import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Empty } from 'atoms';
import styles from './panels.module.scss';

const Items = ({ device: { currentItemsTimestamp, currentItems } }) => {
  return (
    <div
      className={`general-tab row mx-0 pb-2 ${(styles.tabPanel,
      styles.itemsPanel)}`}
    >
      <div>
        {currentItemsTimestamp ? (
          <p className={`w-100 ${styles.label}`}>
            Tagged items detected on{' '}
            {moment(currentItemsTimestamp).format('DD/MM/YYYY')} at{' '}
            {moment(currentItemsTimestamp).format('hh:mm')}
          </p>
        ) : (
          <p>
            <i className="fal fa-tags mr-1" />
            No tags detected
          </p>
        )}
        {currentItems.length > 0 && (
          <ul className={`w-100 mb-2 ${styles.scrollableList}`}>
            {currentItems.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      <small>
        <b>Note:</b> detected tags can be upto 3m from the device.
      </small>
    </div>
  );
};

Items.propTypes = {
  device: PropTypes.shape({
    currentItems: PropTypes.array,
    currentItemsTimestamp: PropTypes.string,
  }),
};

export default Items;
