import React from 'react';
import PropTypes from 'prop-types';
import styles from './ExpandableTable.module.scss';

const ExpandRowButton = ({ row, mapLayer }) => {
  const { onClick, ...props } = row.getExpandedToggleProps();

  return (
    <div {...props} onClick={onClick} className="px-2">
      <div className={styles.expandRowButton}>
        {mapLayer ? (
          <i className={`fal fa-times ${styles.icon}`}></i>
        ) : row.isExpanded ? (
          <i className="fas fa-chevron-up"></i>
        ) : (
          <i className="fas fa-chevron-down"></i>
        )}
      </div>
    </div>
  );
};

ExpandRowButton.propTypes = {
  row: PropTypes.shape({
    isExpanded: PropTypes.bool,
    getExpandedToggleProps: PropTypes.func,
  }),
  mapLayer: PropTypes.bool,
};

export default ExpandRowButton;
