import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import useCalculateCenterAndZoom, {
  DEFAULT_MAP_POSITION,
} from './hooks/useCalculateCenterAndZoom';
import useMap from './hooks/useMap';

import { MapGeometry } from './MapGeometry/MapGeometry';
import { mapGeometryFactory } from './MapGeometry/MapGeometry';

import { InsightsMapMarker } from './MapMarkers/InsightsMapMarker';
import { DevicesMapMarker } from './MapMarkers/DevicesMapMarker';
import { ContextualMapMarker } from './MapMarkers/ContextualMapMarker';
import { SearchBox } from './SearchBox/SearchBox';

import Selector from './Selector';

import { DRAWING_MODES, drawingModeType } from 'components/Geozones/types';
import styles from './Map.module.scss';

const Map = ({
  children,
  connectPoints,
  drawingMode = DRAWING_MODES.NONE,
  onDrawingComplete = () => {},
  onChange,
  searchBoxEnabled = false,
}) => {
  const mapRef = useRef();

  const { center, zoom } = useCalculateCenterAndZoom({
    mapChildren: children,
    mapRef,
  });

  const { onGoogleApiLoaded, googleMapInstance, createMapOptions } = useMap({
    children,
    connectPoints,
    drawingMode,
    onDrawingComplete,
    onChange,
  });

  return (
    <div ref={mapRef} className={styles.wrapper}>
      <GoogleMapReact
        options={createMapOptions}
        defaultCenter={DEFAULT_MAP_POSITION.center}
        defaultZoom={DEFAULT_MAP_POSITION.zoom}
        {...{ center, zoom }}
        bootstrapURLKeys={{
          key: process.env.GOOGLE_MAPS_KEY,
          libraries: ['drawing', 'places'],
        }}
        drawingLibrary
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {React.Children.map(children, child => {
          return React.cloneElement(child, { googleMapInstance }, null);
        })}
      </GoogleMapReact>
      {searchBoxEnabled && googleMapInstance && (
        <div className={styles.searchBoxWrapper}>
          <SearchBox
            map={googleMapInstance.map}
            mapsapi={googleMapInstance.maps}
            placeholder="Search..."
          />
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.array,
  connectPoints: PropTypes.bool,
  drawingMode: drawingModeType,
  onDrawingComplete: PropTypes.func,
  onChange: PropTypes.func,
  searchBoxEnabled: PropTypes.bool,
};

Map.InsightsMapMarker = InsightsMapMarker;
Map.DevicesMapMarker = DevicesMapMarker;
Map.ContextualMapMarker = ContextualMapMarker;

Map.Geometry = MapGeometry;
Map.geometryFactory = mapGeometryFactory;

Map.Selector = Selector;

export default Map;
