import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GeozonesTable from './components/GeozonesTable/GeozonesTable';
import GeozonesEditor from './components/GeozonesEditor';
import GeozonesMap from './components/GeozonesMap';
import ZonesNav from './components/ZonesNav';
import useGeozones from './useGeozones';
import { DRAWING_MODES } from 'components/Geozones/types';

import styles from './geozones.module.scss';

const Geozones = ({ updateGeozoneData, loadedGeozones, isFullAdmin }) => {
  const [zoneToEdit, setZoneToEdit] = useState();
  const [drawingMode, setDrawingMode] = useState(DRAWING_MODES.NONE);
  const [editedGeometryValue, setEditedGeometryValue] = useState();
  const [editedGoogleShapeReference, setGoogleShapeReference] = useState();
  const { tableData, mapData, searchFilter, filterGeozones } = useGeozones(
    loadedGeozones,
  );

  const editHandler = id => {
    const geozoneObject = loadedGeozones.find(zone => zone.id === id);

    setZoneToEdit({ ...geozoneObject, hidden: false });
    setEditedGeometryValue(geozoneObject.geometry);
  };

  const clearEditedGeometry = () => {
    setEditedGeometryValue(null);
    editedGoogleShapeReference && editedGoogleShapeReference.setMap(null);
    setGoogleShapeReference(null);
  };

  const cancelEditHandler = () => {
    setZoneToEdit(null);
    clearEditedGeometry();
  };

  const addGeozoneHandler = () => {
    const DEFAULT_ZONE_COLOR = '#000000';

    setZoneToEdit({
      name: null,
      color: DEFAULT_ZONE_COLOR,
    });
    setDrawingMode(DRAWING_MODES.POLYGON);
  };

  const onGeometryChange = geometry => {
    setEditedGeometryValue(geometry);
  };

  const onDrawingComplete = ({ geometry, shapeInstance }) => {
    setDrawingMode(DRAWING_MODES.NONE);
    setEditedGeometryValue(geometry);
    setGoogleShapeReference(shapeInstance);
  };

  const handleGeozoneSave = geozoneFields => {
    const newGeozoneObj = {
      ...zoneToEdit,
      ...geozoneFields,
      geometry: editedGeometryValue || zoneToEdit.geometry,
    };
    updateGeozoneData(newGeozoneObj);
    cancelEditHandler();
  };

  const handleGeozoneDelete = () => {
    updateGeozoneData(zoneToEdit, true);
    cancelEditHandler();
  };

  const onDrawingModeChange = newMode => {
    const CONFIRMATION_MESSAGE =
      'The current geometry will be cleared. Are You sure?';

    if (editedGeometryValue && !confirm(CONFIRMATION_MESSAGE)) {
      return;
    }

    if (editedGeometryValue) {
      setZoneToEdit({ ...zoneToEdit, hidden: true });
    }

    clearEditedGeometry();
    setDrawingMode(newMode);
  };

  const zoneGeometryAvailableForSave =
    !!editedGeometryValue ||
    !!(zoneToEdit && zoneToEdit.geometry && !zoneToEdit.hidden);

  return (
    <div className={`${styles.dashboardContainer}`}>
      <h1 className={`page-title ${styles.pageTitleDesktop}`}>Geozones</h1>
      <div className={`${styles.dashboardRow} ${styles.dashboardColumns}`}>
        <div className={`${styles.dashboardCol} ${styles.tableCol}`}>
          {zoneToEdit ? (
            <GeozonesEditor
              {...zoneToEdit}
              onDelete={handleGeozoneDelete}
              onSave={handleGeozoneSave}
              drawingMode={drawingMode}
              onDrawingModeChange={onDrawingModeChange}
              hasGeometry={zoneGeometryAvailableForSave}
              isFullAdmin={isFullAdmin}
            />
          ) : (
            <GeozonesTable {...{ data: tableData, onEdit: editHandler }} />
          )}
        </div>
        <div className={`${styles.dashboardCol} ${styles.mapCol}`}>
          <ZonesNav
            {...{
              searchFilter,
              filterFn: filterGeozones,
              onAddGeozone: addGeozoneHandler,
              onClose: cancelEditHandler,
              isEditing: !!zoneToEdit,
            }}
          />
          <div className={`${styles.map}`}>
            <GeozonesMap
              {...{
                data: mapData.map(zone => {
                  return {
                    ...zone,
                    isEdited: zoneToEdit && zone.id === zoneToEdit.id,
                    hidden:
                      zoneToEdit &&
                      zone.id === zoneToEdit.id &&
                      zoneToEdit.hidden,
                  };
                }),
                drawingMode,
                onGeometryChange,
                onDrawingComplete,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Geozones.propTypes = {
  updateGeozoneData: PropTypes.func,
  loadedGeozones: PropTypes.array,
  isFullAdmin: PropTypes.bool,
};

export default props => <Geozones {...props} />;
