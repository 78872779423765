import React from 'react';
import faker from 'faker';
import { generateFakerArray } from 'utils';

import Geozones from './Geozones';

const geozonesSchema = () => ({
  id: faker.random.number({ min: 1, max: 99 }),
  color: faker.commerce.color(),
  name: faker.commerce.productName(),
  geometry: {
    type: 'Feature',
    properties: {
      radius: faker.random.number({ min: 10000, max: 50000 }),
    },
    geometry: { type: 'Point', coordinates: [getFakeLng(), getFakeLat()] },
  },
});

export default {
  title: 'Components | Geozones',
  component: Geozones,
};

export const basic = () => (
  <Geozones initialData={{ geozones: generateFakerArray(geozonesSchema, 5) }} />
);

export const noData = () => (
  <Geozones initialData={{ geozones: generateFakerArray(geozonesSchema, 0) }} />
);

function getFakeLat() {
  return faker.random.number({ min: 49.29899, max: 54.79086 });
}

function getFakeLng() {
  return faker.random.number({ min: 14.24712, max: 23.89251 });
}
