import React from 'react';
import { TextField } from 'atoms';
import PropTypes from 'prop-types';

import styles from './trackingDashboard.module.scss';

export const MapNav = ({ filterFn, activateDeviceRoute }) => (
  // Temporarily hide search bar #176202108
  // Revert change by switching justify-content-end to justify-content-between
  <div className={`d-flex justify-content-end`}>
    {/* <TextField
      placeholder="Search ID or Name"
      iconName="fa-search"
      onInput={filterFn}
      maxWidth="273px"
      marginBottom={0}
    ></TextField> */}
    <a href={activateDeviceRoute} className={styles.activateLink}>
      <button
        type="button"
        className={`btn ${styles.activateButton} ${styles.activateButtonGreen}`}
      >
        <i className="fal fa-plus-square"></i>{' '}
        <span className="d-none d-sm-inline pl-1">Activate Device</span>
      </button>
    </a>
  </div>
);

MapNav.propTypes = {
  filterFn: PropTypes.func,
  activateDeviceRoute: PropTypes.string.isRequired,
};

MapNav.defaultProps = {
  filterFn: () => {},
};

export default MapNav;
