import moment from 'moment';
import { DATE_FORMAT_FOR_REQUESTS } from 'consts/api.js';

const today = moment().format(DATE_FORMAT_FOR_REQUESTS);

export const periodOptions = [
  {
    value: 1,
    text: 'Today',
    from: moment()
      .subtract(1, 'days')
      .endOf('day')
      .format(DATE_FORMAT_FOR_REQUESTS),
    to: moment()
      .endOf('day')
      .format(DATE_FORMAT_FOR_REQUESTS),
  },
  {
    value: 2,
    text: 'Yesterday',
    from: moment()
      .subtract(2, 'days')
      .endOf('day')
      .format(DATE_FORMAT_FOR_REQUESTS),
    to: moment()
      .subtract(1, 'days')
      .endOf('day')
      .format(DATE_FORMAT_FOR_REQUESTS),
  },
  {
    value: 3,
    text: 'Last 7 Days',
    from: getTodayMinus(7, 'days'),
    to: today,
  },
  {
    value: 4,
    text: 'Last 30 Days',
    from: getTodayMinus(30, 'days'),
    to: today,
  },
  {
    value: 5,
    text: 'Last 90 Days',
    from: getTodayMinus(90, 'days'),
    to: today,
  },
  {
    value: 6,
    text: 'Previous Month',
    from: moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(DATE_FORMAT_FOR_REQUESTS),
    to: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format(DATE_FORMAT_FOR_REQUESTS),
  },
  {
    value: 7,
    text: 'Month to Date',
    from: moment()
      .startOf('month')
      .format(DATE_FORMAT_FOR_REQUESTS),
    to: today,
  },
  {
    value: 8,
    text: 'Year to Date',
    from: moment()
      .startOf('year')
      .format(DATE_FORMAT_FOR_REQUESTS),
    to: today,
  },
  {
    value: 9,
    text: 'All Time',
    from: null,
    to: today,
  },
];

export default periodOptions;

function getTodayMinus(howmany, what) {
  return moment()
    .subtract(howmany, what)
    .format(DATE_FORMAT_FOR_REQUESTS);
}
