import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'atoms';
import { drawingModeType } from 'components/Geozones/types';

const GeozonesMap = ({
  data,
  onGeometryChange,
  drawingMode,
  onDrawingComplete,
}) => {
  const handleOnGeometryChange = event => {
    onGeometryChange(event);
  };

  const zones = data.map(({ id, geometry, color, name, isEdited, hidden }) => {
    if (!geometry) {
      return null;
    } else {
      return (
        <Map.Geometry
          key={`${name}-${color}-${id}`}
          {...{
            geometry,
            color,
            label: name,
            editable: hidden ? false : isEdited,
            hidden,
            onChange: handleOnGeometryChange,
          }}
        />
      );
    }
  });

  return (
    <Map
      drawingMode={drawingMode}
      onDrawingComplete={onDrawingComplete}
      onChange={handleOnGeometryChange}
      searchBoxEnabled
    >
      {zones.filter(zone => !!zone)}
    </Map>
  );
};

GeozonesMap.propTypes = {
  data: PropTypes.array.isRequired,
  onPointerClick: PropTypes.func,
  connectPoints: PropTypes.bool,
  onGeometryChange: PropTypes.func,
  drawingMode: drawingModeType,
  onDrawingComplete: PropTypes.func,
};

GeozonesMap.defaultProps = {
  data: [],
};

export default GeozonesMap;
