import PropTypes from 'prop-types';

const { shape, number, string, arrayOf, oneOfType, object } = PropTypes;

export const initialDataType = shape({
  geozones: arrayOf(
    shape({
      id: oneOfType([string, number]),
      name: string,
      color: string,
      geometry: object,
    }),
  ),
});

export const DRAWING_MODES_ARRAY = ['NONE', 'POLYGON', 'CIRCLE'];
export const DRAWING_MODES = DRAWING_MODES_ARRAY.reduce(
  (accumulator, current) => ({ ...accumulator, [current]: current }),
  {},
);
export const drawingModeType = PropTypes.oneOf(DRAWING_MODES_ARRAY);
