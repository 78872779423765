import React, { useState, useEffect } from 'react';
import { useDebounce, useControlledInput } from 'hooks';

function useGeozones(initialData) {
  const [tableData, setTableData] = useState(initialData);
  const [mapData, setMapData] = useState(initialData);

  const {
    inputValue: searchFilter,
    changeHandler: filterGeozones,
  } = useControlledInput('');

  const INPUT_FILTER_DELAY_IN_MS = 500;
  const debouncedSearchTerm = useDebounce(
    searchFilter,
    INPUT_FILTER_DELAY_IN_MS,
  );

  useEffect(() => {
    const filteredData = searchFilter
      ? initialData.filter(geozone => isNameMatched(geozone))
      : initialData;
    setTableData(filteredData);
    setMapData(filteredData);
  }, [debouncedSearchTerm, initialData]);

  return {
    tableData,
    mapData,
    searchFilter,
    filterGeozones,
  };

  function isNameMatched(geozone) {
    if (!geozone.name) {
      return false;
    }

    return geozone.name
      .toUpperCase()
      .includes(debouncedSearchTerm.toUpperCase());
  }
}

export default useGeozones;
