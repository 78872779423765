import ReactOnRails from 'react-on-rails';

import TrackingDashboard from '../components/TrackingDashboard/APILinkedTrackingDashboard';
import Geozones from '../components/Geozones/APILinkedGeozones';
import DeviceView from '../components/DeviceView/APILinkedDeviceView';
import Uploader from 'atoms/Uploader/Uploader';
import InputLinkedMultiSelect from '../components/MultiSelect/InputLinkedMultiSelect';
import UploaderFormWrapper from '../components/UploaderFormWrapper';
import ColorPickerFormWrapper from '../components/ColorPickerFormWrapper';
import { basic as FakeGeozones } from '../components/Geozones/Geozones.stories';

export const registerGlobalReactComponents = () => {
  ReactOnRails.register({
    TrackingDashboard,
    DeviceView,
    Uploader,
    InputLinkedMultiSelect,
    UploaderFormWrapper,
    ColorPickerFormWrapper,
    FakeGeozones,
    Geozones,
  });
};

export default registerGlobalReactComponents;
