import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'atoms';

const DevicesMap = ({ data, contextualData, onPointerClick }) => {
  const [displayContextualData, setDisplayContextualData] = useState();
  const markers = data.map(({ lng, lat, id, name, active, status }) => {
    if (!lng || !lat) {
      return null;
    } else {
      return (
        <Map.DevicesMapMarker
          key={id}
          lat={lat}
          lng={lng}
          label={name}
          active={!!active}
          onClick={() => onPointerClick(id)}
          id={id}
          status={status}
          name="DevicesMapMarker"
        />
      );
    }
  });
  const mapComponents = markers.filter(marker => !!marker);

  for (const [key, value] of Object.entries(contextualData)) {
    if (displayContextualData && displayContextualData.get(key)) {
      let contextData = value.map(({ geometry, properties, color, name }) => {
        return Map.geometryFactory({
          key: `${name}-${color}`,
          geometry,
          properties,
          color,
          label: null,
        });
      });
      mapComponents.push(...contextData.filter(data => !!data));
    }
  }

  return (
    <>
      <Map>{mapComponents}</Map>
      <Map.Selector
        items={Object.keys(contextualData)}
        onSelect={setDisplayContextualData}
      />
    </>
  );
};

DevicesMap.propTypes = {
  data: PropTypes.array.isRequired,
  contextualData: PropTypes.object,
  onPointerClick: PropTypes.func,
};

DevicesMap.defaultProps = {
  data: [],
  contextualData: {},
};

export default DevicesMap;
