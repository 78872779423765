/* eslint-disable prettier/prettier */
import React from 'react';
import { TextField } from 'atoms';
import PropTypes from 'prop-types';

import styles from '../geozones.module.scss';

export const ZonesNav = ({
  searchFilter,
  filterFn,
  onAddGeozone,
  onClose,
  isEditing,
}) => (
  <div className={`${
    isEditing ? 'd-flex justify-content-between' : ''
  }`}>
    <h1 className={`page-title ${styles.pageTitleMobile}`}>Geozones</h1>
    <div
      className={`d-flex ${
        isEditing ? 'justify-content-end' : 'justify-content-between'
        }`}
      style={{ height: '40px' }}
    >
      {isEditing ? (
        <button
          type="button"
          className={`btn btn-outline-secondary ${styles.activateButton}`}
          onClick={onClose}
        >
          <i className="far fa-long-arrow-alt-left"></i>{' '}
          <span className="d-none d-sm-inline">Back to Geozones</span>
        </button>
      ) : (
          <>
            <TextField
              placeholder="Search Geozones"
              iconName="fa-search"
              defaultValue={searchFilter}
              onInput={filterFn}
              maxWidth="273px"
            ></TextField>
            <button
              type="button"
              className={`btn btn-success ${styles.activateButton} `}
              onClick={onAddGeozone}
            >
              <i className="fal fa-plus-square"></i>{' '}
              <span className="d-none d-sm-inline pl-1">Add Geozone</span>
            </button>
          </>
        )}
    </div>
  </div>
  );

ZonesNav.propTypes = {
  filterFn: PropTypes.func,
  onAddGeozone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  searchFilter: PropTypes.string,
};

ZonesNav.defaultProps = {
  filterFn: () => { },
};

export default ZonesNav;
