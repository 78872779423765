import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import General from './panels/General';
import DigitalLabel from './panels/digitalLabel/DigitalLabel';
import Items from './panels/Items';
import { Tabs, Loading, Empty } from 'atoms';

import styles from './DashboardTabs.module.scss';

const IconWithTitle = ({ title, icon }) => (
  <span className={styles.iconWithTitle}>
    <i className={`fal ${icon} `} /> {title}
  </span>
);

IconWithTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const classes = {
  mainClassName: styles.dashboardTabs,
  tabListClassName: 'nav nav-pills nav-justified',
  tabClassName: 'nav-item',
  selectedTabClassName: 'btn-secondary',
  selectedTabPanelClassName: 'pt-4 pr-4 pl-4',
};

const DashboardTabs = ({
  row: {
    values: { id },
    isExpanded,
    ...rest
  },
  showDevicesList,
  mapLayer,
  showMap,
  onDeviceLoaded,
  onDeviceNotLoaded,
}) => {
  const { data: device, revalidate } = useSWR(
    isExpanded ? `${process.env.API_URL}/devices/${id}?minimal=1` : null,
  );

  useEffect(() => {
    if (device && onDeviceLoaded) {
      onDeviceLoaded();
    } else if (!device && onDeviceNotLoaded) {
      onDeviceNotLoaded();
    }
  }, [device, onDeviceLoaded, onDeviceNotLoaded]);

  const tabsData = useMemo(
    () => [
      {
        header: <IconWithTitle icon="fa-home-alt" title="General" />,
        content: device ? (
          <General
            {...{ device }}
            onChange={revalidate}
            showDevicesList={showDevicesList}
            mapLayer={mapLayer}
            showMap={showMap}
          />
        ) : (
          <Loading />
        ),
      },
      {
        header: <IconWithTitle icon="fa-tv" title="Label" />,
        content: device ? (
          <DigitalLabel {...{ device }} onUpload={revalidate} />
        ) : (
          <Loading />
        ),
      },
      {
        header: <IconWithTitle icon="fa-network-wired" title="Items" />,
        content: device ? <Items {...{ device }} /> : <Empty />,
      },
      {
        header: <IconWithTitle icon="fa-chart-line" title="Insights" />,
        url: id ? `/devices/${id}` : undefined,
      },
    ],
    [device],
  );

  return <Tabs {...{ tabsData, classes }} />;
};

DashboardTabs.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    isExpanded: PropTypes.bool,
    values: PropTypes.object,
  }),
  showDevicesList: PropTypes.func,
  mapLayer: PropTypes.bool,
  showMap: PropTypes.func,
  onDeviceLoaded: PropTypes.func,
  onDeviceNotLoaded: PropTypes.func,
};

export default DashboardTabs;
