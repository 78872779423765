import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from 'atoms';

import 'flatpickr/dist/flatpickr.min.css';
import styles from './panels.module.scss';
import { ErrorBox } from 'atoms/ErrorBox/ErrorBox';
import { useGeneral } from './useGeneral';

// Temporarily hidden 2 & 3 days periods
// Ref: #184309749
const deviceUpdateFrequencyOptions = [
  { value: 30, text: '30 seconds' },
  { value: 300, text: '5 minutes' },
  { value: 600, text: '10 minutes' },
  { value: 1200, text: '20 minutes' },
  { value: 1800, text: '30 minutes' },
  { value: 3600, text: '1 hour' },
  { value: 7200, text: '2 hours' },
  { value: 14400, text: '4 hours' },
  { value: 21600, text: '6 hours' },
  { value: 43200, text: '12 hours' },
  { value: 86400, text: '1 day' },
  // { value: 172800, text: '2 days' },
  // { value: 259200, text: '3 days' },
];

const deviceUpdateFrequencyOptionsOnPanic = [
  { value: 3600, text: '1 hour' },
  { value: 7200, text: '2 hours' },
  { value: 14400, text: '4 hours' },
  { value: 21600, text: '6 hours' },
  { value: 43200, text: '12 hours' },
  { value: 86400, text: '1 day' },
  // { value: 172800, text: '2 days' },
  // { value: 259200, text: '3 days' },
];

const GeneralTab = ({
  device: {
    id,
    batteryDeadAt,
    batteryPanic,
    updateFrequencyInSeconds,
    flightModeDurationInSeconds,
    inspectionDate,
    firmwareVersionStm,
    firmwareVersionEsp,
    refreshIntervalLock,
    isFullAdmin,
  },
  onChange,
  showDevicesList,
  mapLayer,
  showMap,
}) => {
  const dateInputRef = useRef(null);
  const {
    onUpdateFrequencyChange,
    onFlightModeDurationChange,
    error,
    flightModeDuration,
  } = useGeneral({
    dateInputRef,
    id,
    inspectionDate,
    onChange,
    flightModeDurationInSeconds,
  });
  const editDeviceUrl = `/devices/${id}/edit`;

  const batteryLevelInfo = () => {
    switch (refreshIntervalLock) {
      case 'panic':
        return (
          <p className={styles.additionalInfo}>
            Due to low battery level, the current update frequency is limited.
          </p>
        );
        break;
      case 'geozone':
        return (
          <p className={styles.additionalInfo}>
            Due to geozone settings, the current update frequency is limited.
          </p>
        );
      default:
        return <div />;
    }
  };

  const updateFrequencyOptions = () => {
    if (refreshIntervalLock == 'panic') {
      return deviceUpdateFrequencyOptionsOnPanic;
    } else if (isFullAdmin) {
      return [
        { value: 1, text: 'Live Tracking' },
        ...deviceUpdateFrequencyOptions,
      ];
    } else {
      return deviceUpdateFrequencyOptions;
    }
  };

  return (
    <>
      <div className={`${styles.tabPanel} row mx-0`}>
        <div className="col-md px-0">
          <div>
            <span className={styles.label}>Device Update Frequency:</span>
            <div className={styles.flex}>
              <Select
                id="general-tab-select"
                initialValue={updateFrequencyInSeconds}
                options={updateFrequencyOptions()}
                onChange={onUpdateFrequencyChange}
                disabled={refreshIntervalLock == 'geozone'}
              />
              {batteryLevelInfo()}
            </div>
          </div>
          <div className="mb-4 mt-3">
            <span className={styles.label}>
              Equipment next inspection date:
            </span>
            <div className={styles.flex}>
              <input
                ref={dateInputRef}
                className="form-control"
                value={moment(inspectionDate).format('DD/MM/YYYY')}
                onChange={() => {}}
              />
              <p className={styles.additionalInfo}>
                {new Date() < moment(inspectionDate)
                  ? 'Next inspection in:'
                  : 'Inspection is past due for:'}
                <span>
                  {inspectionDate ? moment(inspectionDate).fromNow(true) : ''}
                </span>
              </p>
            </div>
          </div>
          <div className="mb-4 mt-3">
            <span className={styles.label}>
              BETA: Radio Silent Mode Duration (S):
            </span>
            <div className={styles.flex}>
              <input
                className="form-control"
                value={flightModeDuration}
                onChange={onFlightModeDurationChange}
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <ErrorBox error={error}></ErrorBox>
      </div>
      <div className={styles.bottomButtons}>
        <a href={editDeviceUrl} className="text-secondary">
          <i className="far fa-edit"></i> Edit Device
        </a>
        <button
          type="button"
          className="text-secondary"
          onClick={mapLayer ? showDevicesList : showMap}
        >
          <i className="fal fa-map-marker-alt mr-1" />
          {`${mapLayer ? 'Show on list' : 'Show on map'}`}
        </button>
      </div>
    </>
  );
};

GeneralTab.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    updateFrequencyInSeconds: PropTypes.number,
    flightModeDurationInSeconds: PropTypes.number,
    batteryDeadAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    batteryPanic: PropTypes.bool,
    inspectionDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    firmwareVersionStm: PropTypes.string,
    firmwareVersionEsp: PropTypes.string,
    statusNote: PropTypes.string,
    refreshIntervalLock: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    isFullAdmin: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  showDevicesList: PropTypes.func,
  mapLayer: PropTypes.bool,
  showMap: PropTypes.func,
};

export default GeneralTab;
