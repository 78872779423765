import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'atoms';
import styles from './InsightsMap.module.scss';

const getFixedNumber = nr => {
  return Number(nr).toFixed(1);
};

const InsightsMap = ({
  markersData,
  zonesData,
  displayZones,
  connectPoints,
  contextualData,
}) => {
  const [displayContextualData, setDisplayContextualData] = useState();
  const markers = markersData.map(
    ({
      lng,
      lat,
      id,
      active,
      timestamp,
      source,
      gpsUncertitude,
      geolocationAccuracy,
    }) => {
      if (!lng || !lat) {
        return null;
      } else {
        return (
          <Map.InsightsMapMarker
            key={id}
            lat={lat}
            lng={lng}
            label={id}
            active={!!active}
            mostRecent={id == markersData.length}
            hoverText={
              <div className={styles.wrapper}>
                <span className={styles.dateText}>
                  {timestamp.local().format('MMM Do YYYY | HH:mm:ss')}
                </span>
                <span className={styles.text}>
                  {`GPS Accuracy: ${
                    gpsUncertitude
                      ? getFixedNumber(gpsUncertitude) + 'm'
                      : 'No data'
                  }`}
                </span>
                <span className={styles.text}>
                  {`Cell/Wifi Accuracy: ${
                    geolocationAccuracy
                      ? getFixedNumber(geolocationAccuracy) + 'm'
                      : 'No data'
                  }`}
                </span>
              </div>
            }
            id={id}
          />
        );
      }
    },
  );

  const zones = zonesData.map(({ geometry, color, name, isEdited, hidden }) => {
    if (!geometry) {
      return null;
    } else {
      return (
        <Map.Geometry
          key={`${name}-${color}`}
          {...{
            geometry,
            color,
            label: name,
            editable: hidden ? false : isEdited,
            hidden,
          }}
        />
      );
    }
  });

  const mapComponents = markers.filter(marker => !!marker);
  if (displayZones) {
    mapComponents.push(...zones.filter(zone => !!zone));
  }

  for (const [key, value] of Object.entries(contextualData)) {
    if (displayContextualData && displayContextualData.get(key)) {
      let contextData = value.map(({ geometry, properties, color, name }) => {
        return Map.geometryFactory({
          key: `${name}-${color}`,
          geometry,
          properties,
          color,
          label: null,
        });
      });
      mapComponents.push(...contextData.filter(data => !!data));
    }
  }

  return (
    <>
      <Map {...{ connectPoints }}>{mapComponents}</Map>
      <Map.Selector
        items={Object.keys(contextualData)}
        onSelect={setDisplayContextualData}
      />
    </>
  );
};

InsightsMap.propTypes = {
  markersData: PropTypes.array.isRequired,
  zonesData: PropTypes.array,
  onPointerClick: PropTypes.func,
  connectPoints: PropTypes.bool,
  displayZones: PropTypes.bool,
  contextualData: PropTypes.object,
};

InsightsMap.defaultProps = {
  markersData: [],
  zonesData: [],
  displayZones: false,
  contextualData: {},
};

export default InsightsMap;
