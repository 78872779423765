import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../../assets/stylesheets/_colors.scss';
import styles from '../insightsMap.module.scss';

export const InsightsMapMarker = ({
  label,
  active = false,
  hoverText,
  onClick,
  id,
  mostRecent,
}) => (
  <>
    <div
      style={{
        backgroundColor: mostRecent ? colors.markerGreen : colors.markerBlue,
      }}
      className={`${styles.mapMarker} point-id-${id}`}
      {...{ onClick }}
    ></div>
    {hoverText && <span className={styles.mapMarkerTooltip}>{hoverText}</span>}
  </>
);

InsightsMapMarker.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  active: PropTypes.bool,
  hoverText: PropTypes.object,
  onClick: PropTypes.func,
  mostRecent: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
