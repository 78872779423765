import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { func, arrayOf, oneOfType, string, instanceOf } from 'prop-types';

export const useDateRange = ({ currentPeriod, setCurrentPeriod }) => {
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    if (dateRange) {
      let periodFrom = currentPeriod[0];
      let periodEnd = moment();

      switch (dateRange.label) {
        case 'Today':
          periodFrom = moment().startOf('day');
          break;
        case 'Yesterday':
          periodFrom = moment()
            .subtract(1, 'day')
            .startOf('day');
          periodEnd = moment()
            .subtract(1, 'day')
            .endOf('day');
          break;
        default:
        case 'Last 7 Days':
          periodFrom = moment()
            .subtract(7, 'days')
            .startOf('day');
          break;
        case 'Last 30 Days':
          periodFrom = moment()
            .subtract(30, 'days')
            .startOf('day');
          break;
        case 'Last 90 Days':
          periodFrom = moment()
            .subtract(90, 'days')
            .startOf('day');
          break;
        case 'Year to Date':
          periodFrom = moment().startOf('year');
          break;
      }

      setCurrentPeriod([periodFrom, periodEnd]);
    }
  }, [dateRange]);

  const resetDateRange = useCallback(() => {
    setDateRange(null);
  }, []);

  return { dateRange, setDateRange, resetDateRange };
};

useDateRange.propTypes = {
  currentPeriod: arrayOf(
    oneOfType([string, instanceOf(Date), instanceOf(moment)]),
  ),
  setCurrentPeriod: func,
};
