import React from 'react';

export const ErrorBox = ({ error }) => {
  if (!error) {
    return null;
  } else {
    return <div className="alert alert-warning">{error}</div>;
  }
};

export default ErrorBox;
