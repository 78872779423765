import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { eventsType } from '../../types';
import { EventsTable } from '../EventsTable';
import useEventsList from './useEventsList';
import underscoresToSpaces from '../../../../utils/underscoresToSpaces/underscoresToSpaces';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { useModal } from 'atoms';
import { useInitialDimensions } from 'hooks';
import moment from 'moment';

export const EventsList = ({ events, deviceId, clearEventsList, period }) => {
  const {
    eventTypes,
    selectedTypes,
    onTypeClick,
    getButtonClass,
    getBadgeClass,
    onClearLogs,
  } = useEventsList(events, period);
  const eventsContentRef = useRef();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useModal();

  const handleClearLogs = async () => {
    await onClearLogs(deviceId);
    clearEventsList();
    closeModal();
  };

  const handleDownload = () => {
    const selectedPeriodMin = moment(period[0]).subtract(1, 'days');
    window.location = `${process.env.API_URL}/devices/${deviceId}/logs/download?dataFrom=${selectedPeriodMin}&dataTo=${period[1]}`;
  };

  const {
    containerDimensions: { width, height },
  } = useInitialDimensions(eventsContentRef);

  return (
    <div
      className="p-2"
      ref={eventsContentRef}
      style={{
        width,
        minHeight: height,
      }}
    >
      <div className="mb-4 modal-events-selector" style={{ minWidth: '40vw' }}>
        <span>
          <strong>Select Event Type: </strong>
        </span>
        {eventTypes.map(eventType => (
          <button
            type="button"
            className={`btn btn-sm mx-1 ${getButtonClass(eventType)}`}
            onClick={() => onTypeClick(eventType)}
            key={eventType}
          >
            {underscoresToSpaces(eventType)}
          </button>
        ))}
        <button
          type="button"
          className="btn btn-sm btn-outline-light button-download"
          onClick={handleDownload}
        >
          Download Diagnostic Data
        </button>
      </div>
      <EventsTable
        selectedTypes={selectedTypes}
        events={events}
        getBadgeClass={getBadgeClass}
      ></EventsTable>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={openModal}
          disabled={!event || !events.length}
        >
          <i className="fas fa-trash-alt"></i> Clear Logs
        </button>
      </div>
      <ConfirmationModal
        {...{
          isOpen: isModalOpen,
          onClose: closeModal,
          onConfirm: handleClearLogs,
          onCancel: closeModal,
          text: 'Delete this logs?',
        }}
      />
    </div>
  );
};

EventsList.propTypes = {
  events: eventsType,
  deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearEventsList: PropTypes.func,
  period: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
  ),
};
