import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import styles from './ExpandableTable.module.scss';

const ExpandableRowComponent = ({
  row,
  renderRowSubComponent,
  columnsNumber,
  mapAndDevice,
  showDevicesList,
  mapLayer,
  showMap,
  onDeviceLoaded,
  onDeviceNotLoaded,
}) => {
  if (!row) return null;

  const { isExpanded } = row;

  return (
    <React.Fragment {...row.getRowProps()}>
      <tr
        className={`${styles.row} 
        ${isExpanded ? styles.expanded : ''} 
        ${mapAndDevice ? styles.mapAndDeviceRow : ''}`}
      >
        {row.cells.map((cell, i) => {
          return (
            <td
              className={`${cell.column.id}Cell`}
              key={`row-cell-${i}`}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
      {renderRowSubComponent && (
        <tr
          className={`${styles.expandableRowWrapper} ${
            isExpanded ? styles.expanded : ''
          } ${mapAndDevice ? styles.mapAndDeviceRowWrapper : ''}`}
        >
          <td
            colSpan={columnsNumber}
            className={`${mapAndDevice ? styles.mapAndDeviceContent : ''}`}
          >
            <Collapsible
              triggerDisabled
              open={isExpanded}
              classParentString={`${
                mapAndDevice ? styles.mapAndDeviceCollapsible : ''
              }`}
            >
              <div className={styles.expandableContent}>
                {renderRowSubComponent({
                  row,
                  showDevicesList,
                  mapLayer,
                  showMap,
                  onDeviceLoaded,
                  onDeviceNotLoaded,
                })}
              </div>
            </Collapsible>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

ExpandableRowComponent.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        getCellProps: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
      }),
    ).isRequired,
    getRowProps: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
  }),
  renderRowSubComponent: PropTypes.func.isRequired,
  columnsNumber: PropTypes.number,
  mapAndDevice: PropTypes.bool,
  showDevicesList: PropTypes.func,
  mapLayer: PropTypes.bool,
  showMap: PropTypes.func,
  onDeviceLoaded: PropTypes.func,
  onDeviceNotLoaded: PropTypes.func,
};

export default ExpandableRowComponent;
