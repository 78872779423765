import React from 'react';
import styles from './tableData.module.scss';
import PropTypes from 'prop-types';

export const TableData = ({ label, data }) => {
  return (
    <div>
      <div className={styles.tableDataContainer}>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.data}>{data}</div>
      </div>
    </div>
  );
};

TableData.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.string,
};

export default TableData;
