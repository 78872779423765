import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Note from './Note';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { useModal, TextField } from 'atoms';
import axios from 'axios';

import styles from './DeviceNotes.module.scss';

const DeviceNotes = ({ id, initialNotes, userName }) => {
  const [notesData, setNotesData] = useState(initialNotes);
  const [noteOpened, setNoteOpened] = useState(null);
  const [inputText, setInputText] = useState('');
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useModal();

  const handleNoteSave = (deviceId, noteId, index, text) => {
    const endpointUrl = `${process.env.API_URL}/devices/${deviceId}/notes/${noteId}`;
    if (text) {
      const newNotesData = [...notesData];
      newNotesData[index].text = text;
      const data = {
        id: noteId,
        text: text,
      };
      axios
        .put(endpointUrl, data)
        .then(() => {
          setNotesData(newNotesData);
        })
        .catch(error => console.log(error.message));

      setNoteOpened(null);
    }
  };

  const handleNoteClose = () => {
    setNoteOpened(null);
  };

  const handleNoteDelete = () => {
    const endpointUrl = `${process.env.API_URL}/devices/${id}/notes/${notesData[noteOpened].id}`;
    setNoteOpened(null);
    const newNotesData = [...notesData];
    axios
      .delete(endpointUrl)
      .then(response => {
        if (response.status == 200) {
          newNotesData.splice(noteOpened, 1);
          setNotesData(newNotesData);
        }
        closeModal();
      })
      .catch(error => console.log(error.message));
  };

  const onDelete = () => {
    openModal();
  };

  const notesArray = notesData.map((note, index) => (
    <Note
      key={`note-${index}`}
      onOpen={() => setNoteOpened(index)}
      handleSave={text => handleNoteSave(id, note.id, index, text)}
      onClose={handleNoteClose}
      onDelete={onDelete}
      isEditing={index === noteOpened}
      {...note}
    />
  ));

  const onChange = event => setInputText(event.target.value);

  const handleNoteAdd = () => {
    const endpointUrl = `${process.env.API_URL}/devices/${id}/notes`;
    if (inputText) {
      const newNotesData = [...notesData];
      const data = {
        date: new Date(),
        author: userName,
        text: inputText,
      };
      axios
        .post(endpointUrl, data)
        .then(response => {
          if (response.status == 200) {
            data.id = response.data.id;
            newNotesData.push(data);
            setNotesData(newNotesData);
            setInputText('');
          }
        })
        .catch(error => console.log(error.message));
    }
  };

  const onEnterPress = event => {
    const ENTER_KEY_CODE = 13;

    if (event.which == ENTER_KEY_CODE || event.keyCode == ENTER_KEY_CODE) {
      handleNoteAdd();
    }
  };

  return (
    <div className={styles.deviceNotes}>
      <div className={styles.inputRow}>
        <TextField
          placeholder="Leave a comment..."
          className="flex-grow-1"
          {...{ onChange, value: inputText, onKeyDown: onEnterPress }}
        />
        <button
          className={`btn btn-success ${styles.saveButton}`}
          onClick={handleNoteAdd}
        >
          Save
        </button>
      </div>
      {notesArray}
      <ConfirmationModal
        {...{
          isOpen: isModalOpen,
          onClose: closeModal,
          onConfirm: handleNoteDelete,
          onCancel: closeModal,
          text: 'Delete this note?',
        }}
      />
    </div>
  );
};

DeviceNotes.propTypes = {
  id: PropTypes.string,
  initialNotes: PropTypes.array.isRequired,
  userName: PropTypes.string.isRequired,
};

DeviceNotes.defaultProps = {
  initialNotes: [],
  userName: '',
};

export default DeviceNotes;
