import React from 'react';
import PropTypes from 'prop-types';
import styles from './Empty.module.css';

const Empty = ({ icon, className, children }) => {
  return (
    <div className={`${styles.emptyInfo} ${className || ''}`}>
      <div className={styles.emptyIcon}>{icon}</div>
      <p className={styles.emptyText}>{children}</p>
    </div>
  );
};

Empty.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Empty.defaultProps = {
  icon: <i className="fal fa-folder-times"></i>,
  text: 'No Data',
};

export default Empty;
