import React from 'react';
import PropTypes from 'prop-types';
import { uploaderType } from 'atoms/Uploader/Uploader';
import DigitalLabelView from './digitalLabelView/DigitalLabelView';
import DigitalLabelUploader from './digitalLabelUploader/DigitalLabelUploader';

import styles from './digitalLabel.module.scss';

const DigitalLabel = ({
  device: {
    id,
    carrier,
    currentLabelFileName,
    pendingLabelFileName,
    currentLabelUrl,
    pendingLabelUrl,
    uploader,
  },
  onUpload,
}) => {
  const isLabelView = currentLabelUrl || pendingLabelUrl;

  return (
    <div className={styles.digitalLabel}>
      {isLabelView && (
        <DigitalLabelView
          currentLabelFileName={currentLabelFileName}
          currentLabelUrl={currentLabelUrl}
          pendingLabelFileName={pendingLabelFileName}
          pendingLabelUrl={pendingLabelUrl}
        />
      )}
      <DigitalLabelUploader
        deviceId={id}
        carrier={carrier}
        uploader={uploader}
        onUpload={onUpload}
      />
    </div>
  );
};

DigitalLabel.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    carrier: PropTypes.array,
    currentLabelFileName: PropTypes.string,
    pendingLabelFileName: PropTypes.string,
    currentLabelUrl: PropTypes.string,
    pendingLabelUrl: PropTypes.string,
    uploader: uploaderType,
  }),
  onUpload: PropTypes.func,
};

export default DigitalLabel;
